
        <h2 mat-dialog-title>
            Booking Widget
            <button mat-icon-button (click)="closeDialog()" style="float:right">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content class="dialog-content">
            <iframe #iframe style="width:100%; height:100%; border:none;"></iframe>
        </mat-dialog-content>
	