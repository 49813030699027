import { HttpClient } from "@angular/common/http";
import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { DatasetActionContainerService } from "app/main/components/dataset/services/dataset-action-container.service";
import { DatasetNavigatorService } from "app/main/components/dataset/services/dataset-navigator.service";
import { DatasetRecordService } from "app/main/components/dataset/services/dataset-record.service";
import { DatasetService } from "app/main/components/dataset/services/dataset.service";
import { AppService } from "app/services/app.service";
import { Subscription } from "rxjs";
import { RELOAD_BOOKING, RELOAD_BOOKING_PACKAGE } from "../../edit-booking.component";
import { ActionDialogComponent } from "../../action-dialog/action-dialog.component";
import { ISelectDatasetRecordDialogData, SelectDatasetRecordDialogComponent } from "app/main/components/dataset/dialogs/select-dataset-record/dialog.component";

@Injectable()
export class TripBookingResourceService{

	public fetchTripBookingPackageSubcription: Subscription;
	public loadPickupMeetingPoint = false;
	public meetingPointPickupResource: any;

	constructor(
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		public datasetNavigatorService: DatasetNavigatorService,
		protected http: HttpClient,
		public appService: AppService,
		public datasetService: DatasetService,
		public matDialog: MatDialog
	){
	
	}

	makeNotActivableReason(bookingResource: any): string{
		if(!bookingResource || !bookingResource.optional) return null;
		if(typeof(bookingResource.max_allotment) === 'undefined') return null;
		if(bookingResource.optional==2) return null;
		if(bookingResource.max_allotment === null) return 'Questa risorsa non ha una regola di allotments';
		if(bookingResource.max_allotment === 0) return 'Questa risorsa non ha allotment';
		return null;
	}

	loadMeetingPoint(bookingResource: any, record?: any): void{
		let meeting_point_id = bookingResource && bookingResource.properties.meeting_point_id.value;
		if(record){
			meeting_point_id = record.id;
		}
		if(!meeting_point_id){
			return;
		}
		this.loadPickupMeetingPoint = true;
		this.datasetService.get<any>('/dataset/meeting_points/detail/' + meeting_point_id)
		.subscribe({
			next: response => {
				this.meetingPointPickupResource = response;
				this.loadPickupMeetingPoint = false;
			},
			error: response => {
				this.loadPickupMeetingPoint = false;
			}
		});
	}

	activateResource(bookingResource: any): MatDialogRef<ActionDialogComponent>{
		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			data: {
				title: 'Azione in corso'
			}
		});

		this.datasetService.post<any>('/dataset/trip_booking_resources/command/activate', {
			trip_booking_resource_id: bookingResource.id
		}).subscribe({
			next: response => {
				this.appService.appEvent.next({
					name: RELOAD_BOOKING_PACKAGE,
					extra: {
						trip_booking_package_id: bookingResource.trip_booking_package_id
					}
				});
				dialogRef.close();
			},
			error: response => {
				dialogRef.componentInstance.resultStatus = 'error';
				dialogRef.componentInstance.loading = false;
				dialogRef.componentInstance.setMessage('Si è verificato un errore');
				dialogRef.componentInstance.setTitle(null);
			}
		});

		return dialogRef;
	}

	deactivateResource(bookingResource: any): MatDialogRef<ActionDialogComponent>{
		const dialogRef = this.matDialog.open(ActionDialogComponent, {
			data: {
				title: 'Azione in corso'
			}
		});

		this.datasetService.post<any>('/dataset/trip_booking_resources/command/deactivate', {
			trip_booking_resource_id: bookingResource.id
		}).subscribe({
			next: response => {
				this.appService.appEvent.next({
					name: RELOAD_BOOKING_PACKAGE,
					extra: {
						trip_booking_package_id: bookingResource.trip_booking_package_id
					}
				});
				dialogRef.close();
			},
			error: response => {
				dialogRef.componentInstance.resultStatus = 'error';
				dialogRef.componentInstance.loading = false;
				dialogRef.componentInstance.setMessage('Si è verificato un errore');
				dialogRef.componentInstance.setTitle(null);
			}
		});

		return dialogRef;
	}

	editMeetingPoint(bookingResource: any): void{
		const data: ISelectDatasetRecordDialogData = {
			datasetCode: 'pivot_trip_resources_trip_meeting_points',
			filters: {
				trip_resource_id: bookingResource.trip_resource_id,
				parentRecordId: bookingResource.trip_resource_id
			},
			title: 'Punto di ritrovo'
		};
		this.matDialog.open(SelectDatasetRecordDialogComponent, {
			data
		}).afterClosed()
		.subscribe(record => {
			if(!record) return;
			this.loadPickupMeetingPoint = true;
			this.datasetService.post(this.datasetACS.getUpdatePropertiesRoute(bookingResource), {
				properties_meeting_point_id: record.id
			})
			.subscribe({
				next: response => {
					this.loadMeetingPoint(bookingResource, record);
					this.appService.appEvent.next({
						name: RELOAD_BOOKING_PACKAGE,
						extra: {
							trip_booking_package_id: bookingResource.trip_booking_package_id
						}
					});
				},
				error: response => {
					console.warn(response);
				}
			});
		});
	}
}
