import { NgModule } from '@angular/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import {CdkStepperModule} from '@angular/cdk/stepper';

import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { DatasetResolver } from './dataset.resolver';
import { DatasetService } from './services/dataset.service';
import { DatasetNavigatorService } from './services/dataset-navigator.service';

import { FormViewerModule } from '../form-viewer/form-viewer.module';
import { DATASET_PROPERTIES_COMPONENTS } from './components/resource-properties';
import { DATA_VIEWER_COMPONENTS } from './components/data-viewer';
import { DatasetUpdateComponent } from './base-actions/update/dataset-update.component';
import { DatasetCreateComponent } from './base-actions/create/dataset-create.component';
import { DatasetListComponent } from './base-actions/list/dataset-list.component';
import { DatasetDetailComponent } from './base-actions/detail/dataset-detail.component';
import { RouterModule } from '@angular/router';
import { CardDataViewComponent } from './components/card-data-viewer/view.component';
import { CommonDialogsModule } from 'app/main/dialogs/dialogs.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseSidebarModule } from '@fuse/components';
import { TripPackageStep1Component } from './components/common-step/trip_packages/steps/step-1/step.component';
import { TripPackageStep2Component } from './components/common-step/trip_packages/steps/step-2/step.component';
import { LanguageSelectModule } from '../language-select/language-select.module';
import { TripPackageStep3Component } from './components/common-step/trip_packages/steps/step-3/step.component';
import { TripPackageStep4Component } from './components/common-step/trip_packages/steps/step-4/step.component';
import { CommonStepViewContainerComponent } from './components/common-step/common-step-view-container/view.component';
import { SmartInterfaceStepViewContainerComponent } from './components/common-step/smart-interface-view-container/view.component';
import { CommonFormStepComponent } from './components/common-step/common-form-step/step.component';
import { CommonDateStepComponent } from './components/common-step/common-date-step/step.component';
import { CustomMatTableComponent } from './components/common-step/smart-interface/mat-table/mat-table.component';
import { ProductNameStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/product-name-step/step.component';
import { SmartInterfaceI18nFormStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/description-i18n-form-step/step.component';
import { TripDestinationStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/trip-destination-step/step.component';
import { TripPackageTripFacilitiesComponent } from './components/common-step/smart-interface/datasets/trip_packages/pivot_trip_packages_trip_facilities/step.component';
import { TripPackageTripCategoriesComponent } from './components/common-step/smart-interface/datasets/trip_packages/pivot_trip_packages_trip_categories/step.component';
import { TripPackageSmartInterfaceMediaComponent } from './components/common-step/smart-interface/datasets/trip_packages/media/step.component';
import { ActualTripsSmartInterfaceComponent } from './components/common-step/smart-interface/datasets/trip_packages/actual-trips/step.component';
import { PriceStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/prices/step.component';
import { OptionSetupStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/options-setup/step.component';
import { MeetingPointUnifiedListItemsComponent } from './components/common-step/smart-interface/datasets/trip_packages/meeting_point_unified_list_items/step.component';
import { AvailabilityStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/availabilities/step.component';
import { CommonBlockStepComponent } from './components/common-step/common-blocks-step/step.component';
import { CommonSubDatasetBlocksStepComponent } from './components/common-step/common-blocks-step/sub-dataset-step.component';
import { CommonSubDatasetFormStepComponent } from './components/common-step/common-form-step/sub-dataset-step.component';
import { CommonI18nFormStepComponent } from './components/common-step/common-i18n-form-step/step.component';
import { CommonI18nBlocksStepComponent } from './components/common-step/common-i18n-blocks-step/step.component';
import { CustomStepperComponent } from './components/common-step/custom-stepper/view.component';
import { CdkStepDirective } from './components/common-step/custom-stepper/cdk-step.component';
import { DocumentFileBlockModule } from '../document-file-block/view.module';
import { DatasetCreateDialogComponent } from './dialogs/core-actions/create/dataset-create-dialog.component';
import { DatasetEditDialogComponent } from './dialogs/core-actions/edit/dataset-edit-dialog.component';
import { DatasetDetailDialogComponent } from './dialogs/core-actions/detail/dataset-detail-dialog.component';
import { DatasetDeleteDialogComponent } from './dialogs/core-actions/delete/dataset-delete-dialog.component';
import { UploadFileDialogComponent } from './dialogs/upload-file-dialog/dialog.component';
import { UploadPdfDialogComponent } from './dialogs/upload-pdf-dialog/dialog.component';
import { GenerateActualTripDialogComponent } from './dialogs/generate-actual-trip-dialog/dialog.component';
import { PriceRulesDialogComponent } from './dialogs/price-rules-dialog/dialog.component';
import { SelectDatasetRecordDialogComponent } from './dialogs/select-dataset-record/dialog.component';
import { DiscountRuleListDetailComponent } from './base-actions/detail/views/discount_rule_list/detail-view.component';
import { CustomPipesModule } from 'app/pipes/custom-pipes.module';
import { DataFilterModule } from '../data-filter/data-filter.module';
import { CarouselBlockComponent } from './components/trip-package-carousel-block/view.component';
import { TripPackageOverviewComponent } from './components/common-step/trip_packages/overview/view.component';
import { MeetingPointListItemsDialogComponent } from './dialogs/meeting-point-list-items-dialog/dialog.component';
import { AddBookingParticipantsDialogComponent } from './dialogs/add-booking-participants-dialog/dialog.component';
import { UserRolePermissionStepComponent } from './components/common-step/user-role-permission-step/step.component';
import { DomainUserRolePermissionStepComponent } from './components/common-step/domain-user-role-permission-step/step.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import { EntityTypesStepComponent } from './components/common-step/entities/steps/entity-types/step.component';
import { ActualTripDetailComponent } from './base-actions/detail/views/actual_trips/detail-view.component';
import { DomainUserComponent } from './base-actions/detail/views/domains/detail-view.component';
import { DomainPackagesComponent } from './base-actions/detail/views/domain_packages/detail-view.component';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory as calendarAdapterFactory } from 'angular-calendar/date-adapters/date-fns';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { InvalidAllotmentsDialogComponent } from './dialogs/invalid-allotments-dialog/dialog.component';
import { ListTableViewComponent } from './base-actions/list/views/table/table.component';
import { DatasetCalendarViewComponent } from './base-actions/list/views/calendar/calendar.component';
import { DatasetCalendarHeaderViewComponent } from './base-actions/list/views/calendar/calendar-header/calendar-header.component';
import { DatasetCommandDialogComponent } from './dialogs/dataset-command-dialog/dialog.component';
import { CommonMapStepComponent } from './components/common-step/common-map-step/step.component';
import { AgmCoreModule } from '@agm/core';
import { BookingPreviewDialogComponent } from './dialogs/booking-preview-dialog/dialog.component';
import { ListCustomViewComponent } from './base-actions/list/views/custom/custom.component';
import { TripBookingDiscountItemViewComponent } from './base-actions/list/views/custom/item-views/trip_booking_discounts/item.component';
import { DatasetRestoreDialogComponent } from './dialogs/core-actions/restore/dataset-restore-dialog.component';
import {IntegrationsStepComponent, WidgetDialogComponent} from './components/common-step/integrations/step.component';
import { PraticaDataComponent } from './base-actions/detail/views/pratica-data/pratica-data.component';
import { ExportDataToolComponent } from './components/export-data-tool/export-data-tool.component';
import { RequestPackageToolComponent } from './components/request-package-tool/request-package-tool.component';
import { EntityB2BStepComponent } from './components/common-step/entities/steps/b2b/step.component';
import { ParticipantListEmailDialogComponent } from './dialogs/participant-list-email-dialog/participant-list-email-dialog.component';
import { SelectDomainDialogComponent } from './dialogs/select-domain-dialog/dialog.component';
import { RequestPackagesDialogComponent } from './dialogs/request-packages-dialog/dialog.component';
import { DomainConfigsComponent } from './base-actions/detail/views/domains/domain-configs/domain-configs.component';
import { PraticaDetailComponent } from './base-actions/detail/views/pratica/detail-view.component';
import { UnifiedListDialogComponent } from './dialogs/unified-list-dialog/dialog.component';
import { CommonDataViewerComponent } from './components/common-step/common-data-viewer-step/step.component';
import { CommonI18nDataViewerStepComponent } from './components/common-step/common-i18n-data-viewer-step/step.component';
import { DomainResolver } from 'app/resolvers/domain.resolver';
import { RenderViewDialogComponent } from './dialogs/render-component-dialog/dialog.component';
import { ActualTripGroupTripResourceItemViewComponent } from './base-actions/list/views/custom/item-views/actual_trip_group_trip_resources/item.component';
import { ActualTripGroupResourcesComponent } from './dialogs/actual-trip-group-resources/dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { ProductBaseInfoStepComponent } from './components/common-step/smart-interface/datasets/products/product-base-info-step/step.component';
import { TripResourceTripCategoriesComponent } from './components/common-step/smart-interface/datasets/products/pivot_trip_resources_trip_categories/step.component';
import { TripResourceTripResourcesComponent } from './components/common-step/smart-interface/datasets/products/pivot_trip_resource_trip_resources/step.component';
import { ProductI18nFormStepComponent } from './components/common-step/smart-interface/datasets/products/description-i18n-form-step/step.component';
import { ProductMediaComponent } from './components/common-step/smart-interface/datasets/products/media/step.component';
import { ProductAdvancedBlockStepComponent } from './components/common-step/smart-interface/datasets/products/advanced/step.component';
import { ProductSubDatasetBlocksStepComponent } from './components/common-step/smart-interface/datasets/products/advanced/sub-dataset-step.component';
import { ProductFixedAllotmentComponent } from './components/common-step/smart-interface/datasets/products/form-fixed-allotment/step.component';
import { DatasetListDialogComponent } from './dialogs/dataset-list-dialog/dialog.component';
import { PlafondComponent } from './base-actions/detail/views/plafond/detail-view.component';
import { ActualTripParticipantsListComponent } from './dialogs/actual-trip-participants-list/participant-list-dialog.component';
import { TripPackageReselledStep0Component } from './components/common-step/trip_packages/steps/reselled-step-0/step.component';
import { TripPackageReselledStep2Component } from './components/common-step/trip_packages/steps/reselled-step-2/step.component';
import { TripPackageReselledStep3Component } from './components/common-step/trip_packages/steps/reselled-step-3/step.component';
import { ResellProductNameStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/resell-product-name-step/step.component';
import { ResellTripDestinationStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/resell-trip-destination-step/step.component';
import { ResellTripPackageTripCategoriesComponent } from './components/common-step/smart-interface/datasets/trip_packages/resell-trip-category-step/step.component';
import { ResellSmartInterfaceI18nFormStepComponent } from './components/common-step/smart-interface/datasets/trip_packages/resell-description-i18n-form-step/step.component';
import { ResellTripPackageTripFacilitiesComponent } from './components/common-step/smart-interface/datasets/trip_packages/resell-trip-facility-step/step.component';
import { ResellTripPackageSmartInterfaceMediaComponent } from './components/common-step/smart-interface/datasets/trip_packages/resell-media/step.component';
import { TransmissionDataComponent } from './base-actions/detail/views/transmission-data/transmission-data.component';
import { NewTripPackageOverviewDialogComponent } from './dialogs/new-trip-package-overview/dialog.component';
import { TripBookingParticipantItemViewComponent } from './base-actions/list/views/custom/item-views/trip_booking_participants/item.component';
import { TripBookingResourceItemViewComponent } from './base-actions/list/views/custom/item-views/trip_booking_resources/item.component';
import { ActualTripDateComponent } from './dialogs/actual-trip-date/dialog.component';
import { TripResourceTripDestinationsComponent } from './components/common-step/smart-interface/datasets/products/pivot_trip_resources_trip_destinations/step.component';
import { TripOptionForVirtualTripsListItemViewComponent } from './base-actions/list/views/custom/item-views/trip_options_for_virtual_trips/item.component';
import { DeleteAndRefundDialogComponent } from 'app/main/dialogs/delete-and-refund-dialogs/dialog.component';
import { ResourceInputsComponent } from './components/common-step/resources/resource-inputs/resource-inputs.component';

@NgModule({
	declarations: [
		DatasetUpdateComponent,
		DatasetCreateComponent,
		DatasetListComponent,
		DatasetDetailComponent,

		/** DETAIL CUSTOM VIEWS */
		DiscountRuleListDetailComponent,
		ActualTripDetailComponent,
		DomainUserComponent,
		DomainPackagesComponent,
		PlafondComponent,
		PraticaDetailComponent,
		TransmissionDataComponent,
		PraticaDataComponent,

		DatasetCalendarHeaderViewComponent,
		DatasetCalendarViewComponent,
		ListTableViewComponent,
		ListCustomViewComponent,
		DATASET_PROPERTIES_COMPONENTS,
		TripBookingDiscountItemViewComponent,
		TripBookingParticipantItemViewComponent,
		TripBookingResourceItemViewComponent,
		ActualTripGroupTripResourceItemViewComponent,
		TripOptionForVirtualTripsListItemViewComponent,

		/** DIALOGS */
		DatasetCreateDialogComponent,
		DatasetEditDialogComponent,
		DatasetDetailDialogComponent,
		DatasetDeleteDialogComponent,
		DatasetRestoreDialogComponent,
		UploadFileDialogComponent,
		UploadPdfDialogComponent,
		GenerateActualTripDialogComponent,
		PriceRulesDialogComponent,
		SelectDatasetRecordDialogComponent,
		NewTripPackageOverviewDialogComponent,
		DatasetListDialogComponent,
		MeetingPointListItemsDialogComponent,
		AddBookingParticipantsDialogComponent,
		InvalidAllotmentsDialogComponent,
		DatasetCommandDialogComponent,
		BookingPreviewDialogComponent,
		ParticipantListEmailDialogComponent,
		SelectDomainDialogComponent,
		RequestPackagesDialogComponent,
		DomainConfigsComponent,
		UnifiedListDialogComponent,
		RenderViewDialogComponent,
		ActualTripGroupResourcesComponent,
		ActualTripParticipantsListComponent,
		ActualTripDateComponent,
		DeleteAndRefundDialogComponent,

		DATA_VIEWER_COMPONENTS,

		CardDataViewComponent,

		/** Entity steps */
		EntityTypesStepComponent,
		EntityB2BStepComponent,

		/** Trip Packages Steps */
		TripPackageStep1Component,
		TripPackageStep2Component,
		TripPackageStep3Component,
		TripPackageStep4Component,
		TripPackageOverviewComponent,
		IntegrationsStepComponent,
		WidgetDialogComponent,

		/** Trip Packages Resell Steps */
		TripPackageReselledStep0Component,
		TripPackageReselledStep2Component,
		TripPackageReselledStep3Component,

		/** User Role Steps */
		UserRolePermissionStepComponent,
		/** Domain User Role Steps */
		DomainUserRolePermissionStepComponent,

		/** Common Generic Steps */
		CommonStepViewContainerComponent,
		SmartInterfaceStepViewContainerComponent,
		CommonDataViewerComponent,
		CommonFormStepComponent,
		CommonDateStepComponent,
		CommonBlockStepComponent,
		CommonSubDatasetBlocksStepComponent,
		CommonSubDatasetFormStepComponent,
		CommonI18nFormStepComponent,
		CommonI18nDataViewerStepComponent,
		CommonI18nBlocksStepComponent,
		CustomStepperComponent,
		CdkStepDirective,
		CommonMapStepComponent,

		/** Smart Interface components */
		CustomMatTableComponent,
		ProductNameStepComponent,
		TripDestinationStepComponent,
		SmartInterfaceI18nFormStepComponent,
		TripPackageTripFacilitiesComponent,
		TripPackageTripCategoriesComponent,
		TripPackageSmartInterfaceMediaComponent,
		ActualTripsSmartInterfaceComponent,
		PriceStepComponent,
		OptionSetupStepComponent,
		MeetingPointUnifiedListItemsComponent,
		AvailabilityStepComponent,

		ResellProductNameStepComponent,
		ResellTripDestinationStepComponent,
		ResellTripPackageTripCategoriesComponent,
		ResellSmartInterfaceI18nFormStepComponent,
		ResellTripPackageTripFacilitiesComponent,
		ResellTripPackageSmartInterfaceMediaComponent,

		/** Products dataset components */
		ProductBaseInfoStepComponent,
		TripResourceTripCategoriesComponent,
		TripResourceTripResourcesComponent,
		TripResourceTripDestinationsComponent,
		ProductI18nFormStepComponent,
		ProductMediaComponent,
		ProductAdvancedBlockStepComponent,
		ProductSubDatasetBlocksStepComponent,
		ProductFixedAllotmentComponent,

		CarouselBlockComponent,

		ExportDataToolComponent,
		RequestPackageToolComponent,
		ResourceInputsComponent
	],
	imports: [
		MatButtonModule, MatDividerModule, MatFormFieldModule, MatIconModule,
		MatMenuModule, MatSelectModule, MatTableModule, MatTabsModule,
		MatProgressSpinnerModule, MatProgressBarModule, MatPaginatorModule,
		MatCardModule, MatExpansionModule, MatToolbarModule, MatInputModule,
		MatFormFieldModule, MatTooltipModule, MatBadgeModule, MatDialogModule,
		MatListModule, MatChipsModule, DocumentFileBlockModule, MatCheckboxModule,
		DragDropModule,
		ImageCropperModule,
		AgmCoreModule,
		MatSliderModule,
		MatSnackBarModule,
		MatDatepickerModule,


		MatStepperModule,
		CdkStepperModule,
		FlexLayoutModule,

		FuseSidebarModule,
		CustomPipesModule,

		FormViewerModule,
		LanguageSelectModule,

		FuseSharedModule,
		TranslateModule.forChild(),
		RouterModule,
		CommonDialogsModule,
		DataFilterModule,
		MatRadioModule,

		AngularCalendarModule.forRoot({
			provide   : DateAdapter,
			useFactory: calendarAdapterFactory
		}),
	],
	entryComponents: [
		DatasetCreateDialogComponent,
		DatasetEditDialogComponent,
		DatasetDetailDialogComponent,
		DatasetDeleteDialogComponent,
		DatasetRestoreDialogComponent,
		UploadFileDialogComponent,
		UploadPdfDialogComponent,
		GenerateActualTripDialogComponent,
		PriceRulesDialogComponent,
		SelectDatasetRecordDialogComponent,
		NewTripPackageOverviewDialogComponent,
		DatasetListDialogComponent,
		MeetingPointListItemsDialogComponent,
		AddBookingParticipantsDialogComponent,
		InvalidAllotmentsDialogComponent,
		DatasetCommandDialogComponent,
		BookingPreviewDialogComponent,
		ParticipantListEmailDialogComponent,
		SelectDomainDialogComponent,
		UnifiedListDialogComponent,
		RenderViewDialogComponent,
		RequestPackagesDialogComponent,
		ActualTripParticipantsListComponent,
		DomainConfigsComponent,
		DomainPackagesComponent,
		PlafondComponent,
		ActualTripGroupResourcesComponent,
		ActualTripDateComponent,
		DeleteAndRefundDialogComponent,
		ResourceInputsComponent,
		WidgetDialogComponent
	],
	exports: [
		DatasetDetailComponent,
		DatasetCreateComponent,
		DatasetListComponent,
		DatasetUpdateComponent,
		ListTableViewComponent,
		DATASET_PROPERTIES_COMPONENTS,
		DATA_VIEWER_COMPONENTS,
		CardDataViewComponent,

		DatasetCreateDialogComponent,
		DatasetEditDialogComponent,
		DatasetDetailDialogComponent,
		DatasetDeleteDialogComponent,
		DatasetRestoreDialogComponent,
		UploadFileDialogComponent,
		UploadPdfDialogComponent,
		GenerateActualTripDialogComponent,
		PriceRulesDialogComponent,
		SelectDatasetRecordDialogComponent,
		NewTripPackageOverviewDialogComponent,
		DatasetListDialogComponent,
		AddBookingParticipantsDialogComponent,
		InvalidAllotmentsDialogComponent,
		ParticipantListEmailDialogComponent,
		SelectDomainDialogComponent,
		UnifiedListDialogComponent,
		RenderViewDialogComponent,
		RequestPackagesDialogComponent,
		ActualTripParticipantsListComponent,
		ActualTripGroupResourcesComponent,
		DomainConfigsComponent,
		DomainPackagesComponent,
		PlafondComponent,
		ActualTripDateComponent,
		DeleteAndRefundDialogComponent,
		ResourceInputsComponent
	],
	providers: [
		DatasetResolver,
		DatasetService,
		DatasetNavigatorService,
		DomainResolver
	]
})
export class DatasetModule{}
