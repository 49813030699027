import {
	IDatasetConfig,
	DatasetActionOpenType,
	ListViewItemTypes,
	ValueTypes,
	InputTypes,
	FieldViews
} from 'app/interfaces';
import {fieldsMap} from './fields';
import generalForm from './forms/general.form';
import detailForm from './forms/detail.form';
import { datasetDetailPage } from 'app/helpers/dataset-routing.helper';
import {IDataContainerService} from "../../../interfaces/data-container.interface";
import {DatasetNavigatorService} from "../../../main/components/dataset/services/dataset-navigator.service";
import {LoadingDialogComponent} from "../../../main/dialogs/loading-dialog/dialog.component";
import {
	DatasetActionContainerService,
	DatasetEvents
} from "../../../main/components/dataset/services/dataset-action-container.service";

const beforeCallDetail = {
	name: 'record',
	path: '/pivot/childrenResources/dataset/trip_resources/:parentRecordId/detail/:recordId',
};
export const PivotTripResourceTripResourcesConfig: IDatasetConfig = {
	name: 'pivot_trip_resource_trip_resources',
	title: 'Children Resources',
	singleTitle: 'Children Resource',
	ajaxDatasetCode: 'trip_resources',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: false,
		list: true
	},
	formConfigsMap: {
		generalForm,
		detailForm,
	},
	pivot: {
		pivotCode: 'childrenResources',
		foreignKey: 'trip_resource_id',
		parentForeignKey: 'parent_trip_resource_id',
		parentDatasetCode: 'trip_resources',
		commandSync: 'tpl1_trip_resources_pivots',
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: generalForm
			},
		},
		list: {
			getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
				params.with_relations = 'trip_resources';
				return params;
			},
			openType: DatasetActionOpenType.PAGE,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'created_at',
				defaultSortDirection: 'desc',
				columns: [
					{
						width: '50px',
						title: 'Opzione Predefinita',
						key: 'primary_option',
						fieldDefinition: {
							key: 'primary_option',
							name: 'Opzione predefinita',
							valueType: ValueTypes.NUMBER,
							fieldView: FieldViews.HTML,
							getDisplayValue(record: any): string {
								if (record.primary_option === true) {
									return '<mat-icon alt="Usato per l\'opzione preselezionata" role="img" class="primary-fg mat-icon material-icons mat-icon-no-color">check_circle_outline</mat-icon>';
								}
								return '';
							},
						},
					},
					{
						title: 'Codice',
						key: 'resource_code',
						fieldDefinition:{
							key: 'resource_code',
							name: 'Codice',
							valueType: ValueTypes.STRING,
							getDisplayValue(record){
								
								return record.code;
							}
						},
						onClick(record, resourceACS, resourceNavigatorService): void{
							resourceNavigatorService
								.showDetail(resourceACS, record);
						}
						
					},
					{
						title: 'Descrizione',
						key: 'resource_description',
						fieldDefinition:{
							key: 'resource_description',
							name: 'Descrizione',
							valueType: ValueTypes.STRING,
							getDisplayValue(record){
								return record.description;
							}
						},
					},
					{
						title: 'Tipo / Tipologia',
						key: 'resource_type_sub_types_view',
						fieldDefinition:{
							key: 'resource_type_sub_types_view',
							name: 'Tipo / Tipologia',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								const resourceType = record.properties.resource_type.label || '---',
									resourceSubType = record.properties.resource_sub_types.value || '---';
								return resourceType + " / " + resourceSubType;
							}
						}
					},
					{
						title: 'Prezzo',
						key: 'resource_price',
						fieldDefinition:{
							key: 'resource_price',
							name: 'Prezzo',
							valueType: ValueTypes.STRING,
							getDisplayValue(record: any): string{
								return record.properties.price.value || '---';
							}
						}
					},
					
				],
				actions: ['delete','create'],
				customActions: [
					{
						buttonColor: 'primary',
						buttonIcon: 'check',
						actionCode: 'edit',
						tooltip: 'Imposta come opzione predefinita',
						onClick(event: any, record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): void {
							const dialogRef = datasetNavigatorService.openDialog(LoadingDialogComponent, {
								title: 'Azione in corso',
							});
							datasetNavigatorService.datasetService
								.post<any>('/dataset/trip_resources/command/set_as_primary_option', {
									trip_resource_id: record.pivot.parent_trip_resource_id,
									primary_option_id: record.id
								})
								.subscribe({
									next: response => {
										if (dataContainerService instanceof DatasetActionContainerService) {
											dataContainerService.datasetEvent.next({
												eventName: DatasetEvents.ACTION_REALOAD_LIST,
											});
										}
										dialogRef.close();
									},
									error: response => {
										dialogRef.close();
									},
								});
						},
						showIf(record: any, dataContainerService: IDataContainerService): boolean {
							return record.primary_option !== true;
						},
					},
				],
			}
		}
	}
};
