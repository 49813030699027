<button mat-flat-button class="mr-4 my-8" color="accent" (click)="onAdd()">{{ 'Aggiungi' | translate  }}</button>
<mat-progress-bar *ngIf='loading' mode='indeterminate'></mat-progress-bar>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Tipo </th>
        <td mat-cell *matCellDef="let element">
			<span ngIf="element.type=='select'">Select numerica</span>
			<span ngIf="element.type=='input'">Testo</span>
		</td>
    </ng-container>
    <!--<ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Nome </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>-->
    <ng-container matColumnDef="labels">
        <th mat-header-cell *matHeaderCellDef> Etichette </th>
        <td mat-cell *matCellDef="let element"> 
			<div>IT: {{element.label.it}} </div>
			<div>EN: {{element.label.en}}</div>
		</td>
    </ng-container>
	<ng-container matColumnDef="config">
		<th mat-header-cell *matHeaderCellDef> Config </th>
		<td mat-cell *matCellDef="let element" style="text-align: center"> 
			<ng-container *ngIf="element.type=='select'">
				<div>Min: {{element.min}}</div>
				<div>Max: {{element.max}}</div>
				<div>Valore iniziale: {{element.value}}</div>
			</ng-container>
		</td>
	</ng-container>
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Azioni </th>
        <td mat-cell *matCellDef="let element; let i = index">
            <div class="action-button">
                <button mat-icon-button color='accent' (click)="onEdit(element,i)"><mat-icon>edit</mat-icon></button>
                <button mat-icon-button *ngIf="element.name!=='participants'" (click)="onDelete(element)"><mat-icon color='warn'>delete</mat-icon></button>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


