<h2>{{'DETAIL' | translate | titlecase}} {{datasetACS.datasetConfig.singleTitle | translate | titlecase}}</h2>

<div class='mb-24 domain-logo'>
	<document-file-block	
		[record]='datasetRS.record.value'
		[documentFileKey]='"logo"'
		[type]='"image"'
		[uploadRoute]='"/dataset/domains/command/upload_image"'
		[uploadData]='{domain_id: datasetRS.record.value.id}'
		(changed)='reload()'
		[imageOptions]="customImageOptions"
		[svgEnablded]="true"
		[canEditParam]="true"
		[uploadLabel]='"Carica Logo" | translate'
	></document-file-block>
</div>

<br/>

<div class="domain-info" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxLayoutGap="10px">
	<div *ngIf="datasetRS.record.value.father && datasetRS.record.value.father.code != 'root'">
		<div fxFlex>
			<mat-card>
				<h2>{{'Dominio padre' | translate}}</h2>
				<p><b>{{'Codice' | translate}}:</b> {{datasetRS.record.value.father?.code}}</p>
				<p><b>{{'Nome' | translate}}:</b> {{datasetRS.record.value.father?.display_name}}</p>
			</mat-card>
		</div>
	</div>
	<div>
		<ng-container *ngIf='datasetACS.actionConfig && datasetACS.actionConfig.viewConfig.formConfig'>
			<div fxFlex card-data-viewer
				[elevate]="true"
				[record]='datasetRS.record.value'
				[datasetACS]='datasetACS'
				[viewFormConfig]='datasetACS.actionConfig.viewConfig.formConfig'
				[editFormConfig]="datasetACS.datasetConfig.formConfigsMap['editForm']"
			></div>
		</ng-container>
	</div>
</div>


<mat-tab-group [selectedIndex]='stepperIndex'>

	<mat-tab [label]="'Utenti Dominio' | translate">
		<ng-template matTabContent>
			<dataset-list
				[showTitle]='false'
				[elevate]='false'
				datasetCode='domain_users'
				[parentDatasetACS]='datasetACS'
				[parentDatasetRS]='datasetRS'
				[filters]='{domain_id: datasetRS.recordId }'
				[autoFetchData]='false'>
			</dataset-list>
		</ng-template>
	</mat-tab>
	<mat-tab [label]="'Ruoli Dominio' | translate">
		<ng-template matTabContent>
			<dataset-list
				[showTitle]='false'
				[elevate]='false'
				datasetCode='domain_user_roles'
				[parentDatasetACS]='datasetACS'
				[parentDatasetRS]='datasetRS'
				[filters]='{domain_id: datasetRS.recordId }'
				[autoFetchData]='false'>
			</dataset-list>
		</ng-template>
	</mat-tab>
	<mat-tab [label]="'Sotto Domini' | translate">
		<ng-template matTabContent>
			<dataset-list
				[showTitle]='false'
				[elevate]='false'
				datasetCode='domains'
				[parentDatasetACS]='datasetACS'
				[parentDatasetRS]='datasetRS'
				[filters]='{children: 1, domain_path: datasetRS.record.value.domain_path}'
				[autoFetchData]='false'
				[enableCreate]='false'>
			</dataset-list>
		</ng-template>
	</mat-tab>

	<mat-tab *ngIf='userIsAdmin' [label]='"Configurazione [Raw] (solo Admin)" | translate'>
		<ng-template matTabContent>
			<mat-card>
				<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
				<div class='mt-24' *ngIf='datasetACS.ready.value'>
					<pre style='display:none;'>{{datasetRS.record.value.domain_config | json}}</pre>
					<form-viewer
						#configFormViewer
						[dataContainerService]='datasetACS'
						[formConfig]='domainConfigRawFormViewer'
						[record]='datasetRS.record.value.domain_config'
					></form-viewer>
			
					<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
						<button color='accent' [disabled]='datasetACS.loading.value' mat-flat-button (click)='saveRawConfig()'>
							<span>{{'Salva' | translate}}</span>
						</button>
					</div>
				</div>
			</mat-card>
		</ng-template>
	</mat-tab>

	<mat-tab [label]="'Configurazioni Dominio' | translate">
		<ng-template matTabContent>
			<mat-expansion-panel *ngIf="datasetRS.record.value.expanson_panel_permission">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Generali' | translate}}
					</mat-panel-title>
				</mat-expansion-panel-header>

				<domain-configs
					[elevate]='false'
					[record]="datasetRS.record.value.domain_conf.app"
					[datACS]='datasetACS'
					[viewFormConfig]="'appForm'"
					[showActions]="true"
					[rowDb]="datasetRS.record.value">
				</domain-configs>

				<domain-configs
					[elevate]='false'
					[record]="datasetRS.record.value.domain_conf.template"
					[datACS]='datasetACS'
					[viewFormConfig]="'appTemplateForm'"
					[showActions]="true"
					[rowDb]="datasetRS.record.value">
				</domain-configs>

				<!-- client domain -->
				<ng-container *ngIf='datasetRS.record.value.client'>
					<h2>Credenziali API: {{datasetRS.record.value.client.name}}</h2>
					<mat-list>
						<mat-list-item><b>Client ID</b>: {{datasetRS.record.value.client.id}}</mat-list-item>
						<mat-list-item><b>Client secret</b>: {{datasetRS.record.value.client.secret}}</mat-list-item>
					</mat-list>
				</ng-container>

				<div *ngIf='datasetRS.record.value.callable_actions'>
					<ng-container *ngFor="let callableAction of datasetRS.record.value.callable_actions">
						<a *ngIf="callableAction.redirect && callableAction.url" target="_blank" color='accent' mat-flat-button [href]="callableAction.url">
							<span>{{callableAction.label}}</span>
						</a>
					</ng-container>
				</div>
			</mat-expansion-panel>

			<mat-expansion-panel *ngIf="datasetRS.record.value.expanson_panel_permission">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Configurazioni FTP Pratiche' | translate}}
					</mat-panel-title>
				</mat-expansion-panel-header>
				<domain-configs
					[elevate]='false'
					[record]="datasetRS.record.value.domain_conf.filesystems.pratiche_server"
					[datACS]='datasetACS'
					[viewFormConfig]="'praticheServerForm'"
					[showActions]="true"
					[rowDb]="datasetRS.record.value">
				</domain-configs>

			</mat-expansion-panel>

			<mat-expansion-panel *ngIf="datasetRS.record.value.expanson_panel_permission">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Configurazione sito esterno' | translate}}
					</mat-panel-title>
				</mat-expansion-panel-header>

				<domain-configs
					[record]="datasetRS.record.value.domain_conf.externalsite"
					[datACS]='datasetACS'
					[viewFormConfig]="'externalsiteForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
			</mat-expansion-panel>

			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Tour Operators' | translate}}
					</mat-panel-title>
				</mat-expansion-panel-header>

				<h2>VIATOR <a class="material-icons" [href]='baseUrl + "/docs/touroperators/viator/viator.php"' target="_blank">help</a></h2>
				<domain-configs
					*ngIf='datasetRS.record.value.domain_conf.touroperators.viator'
					[record]="datasetRS.record.value.domain_conf.touroperators.viator"
					[datACS]='datasetACS'
					[viewFormConfig]="'viatorForm'"
					[showActions]="true"
					[rowDb]="datasetRS.record.value">
				</domain-configs>

				<h2>GET YOUR GUIDE <a class="material-icons" [href]='baseUrl + "/docs/touroperators/gyg/gyg.php"' target="_blank">help</a></h2>
				<domain-configs
					*ngIf='datasetRS.record.value.domain_conf.touroperators.gyg'
					[record]="datasetRS.record.value.domain_conf.touroperators.gyg"
					[datACS]='datasetACS'
					[viewFormConfig]="'gygForm'"
					[showActions]="true"
					[rowDb]="datasetRS.record.value">
				</domain-configs>

				<h2>TOUR CMS <a class="material-icons" [href]='baseUrl + "/docs/touroperators/tourcms/configurations.php"' target="_blank">help</a></h2>
				<domain-configs
					*ngIf="datasetRS.record.value.domain_conf.touroperators['tour-cms']"
					[record]="datasetRS.record.value.domain_conf.touroperators['tour-cms']"
					[datACS]='datasetACS'
					[viewFormConfig]="'tourCmsForm'"
					[showActions]="true"
					[rowDb]="datasetRS.record.value">
				</domain-configs>
				
				<!-- coming soon -->
				<h2>BOKUN <a class="material-icons" [href]='baseUrl + "/docs/touroperators/bokun/configurations.php"' target="_blank">help</a></h2>
				<domain-configs
					*ngIf='datasetRS.record.value.domain_conf.touroperators.bokun'
					[record]="datasetRS.record.value.domain_conf.touroperators.bokun"
					[datACS]='datasetACS'
					[viewFormConfig]="'bokunForm'"
					[showActions]="true"
					[rowDb]="datasetRS.record.value">
				</domain-configs>
			</mat-expansion-panel>

			<mat-expansion-panel *ngIf="datasetRS.record.value.expanson_panel_permission">
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Configurazione metodi di pagamento' | translate}} (zverp_booking_engine) 
					</mat-panel-title>
				</mat-expansion-panel-header>

				<h2>PAYPAL</h2>
				<domain-configs
					class="paypal_configuration"
					[record]="datasetRS.record.value.domain_conf.method_payments.paypal"
					[datACS]='datasetACS'
					[viewFormConfig]="'paypalForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
				
				<h2>STRIPE</h2>
				<domain-configs
					class="stripe_configuration"
					[record]="datasetRS.record.value.domain_conf.method_payments.stripe"
					[datACS]='datasetACS'
					[viewFormConfig]="'stripeForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
			</mat-expansion-panel>

			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Contabilità' | translate}} 
					</mat-panel-title>
				</mat-expansion-panel-header>

				<h2>eAgency</h2>
				<domain-configs 
					class="eagency"
					[record]="datasetRS.record.value.domain_conf.modules && datasetRS.record.value.domain_conf.modules.eagency"
					[datACS]='datasetACS'
					[viewFormConfig]="'eagencyForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>

				<h2>Corrispettivi Server RT - KiaraCloud</h2>
				<domain-configs
					class="kiara_cloud_server_rt"
					[record]="datasetRS.record.value.domain_conf.modules && datasetRS.record.value.domain_conf.modules.kiara_cloud_api"
					[datACS]='datasetACS'
					[viewFormConfig]="'kiaraCloudForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>

				<h2>Fatture in cloud</h2>
				<domain-configs
					class="fatture_in_cloud"
					[record]="datasetRS.record.value.domain_conf.modules && datasetRS.record.value.domain_conf.modules.fatture_in_cloud"
					[datACS]='datasetACS'
					[viewFormConfig]="'fattureInCloudForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
			</mat-expansion-panel>

			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Twilio' | translate}} 
					</mat-panel-title>
				</mat-expansion-panel-header>
				<h2>SMS</h2>
				<domain-configs 
					class="eagency"
					[record]="datasetRS.record.value.domain_conf.twilio && datasetRS.record.value.domain_conf.twilio.sms"
					[datACS]='datasetACS'
					[viewFormConfig]="'twilioSMSForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
			</mat-expansion-panel>

			<mat-expansion-panel>
				<mat-expansion-panel-header>
					<mat-panel-title>
						{{'Canali' | translate}} 
					</mat-panel-title>
				</mat-expansion-panel-header>
				<h2>App Mobile</h2>
				<domain-configs 
					class="app_mobile"
					[record]="datasetRS.record.value.domain_conf.channels && datasetRS.record.value.domain_conf.channels.app_mobile"
					[datACS]='datasetACS'
					[viewFormConfig]="'appMobileForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
				<h2>Totem</h2>
				<domain-configs 
					class="totem"
					[record]="datasetRS.record.value.domain_conf.channels && datasetRS.record.value.domain_conf.channels.totem"
					[datACS]='datasetACS'
					[viewFormConfig]="'totemForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
				<h2>Sito Web</h2>
				<domain-configs 
					class="website"
					[record]="datasetRS.record.value.domain_conf.channels && datasetRS.record.value.domain_conf.channels.website"
					[datACS]='datasetACS'
					[viewFormConfig]="'websiteForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
				<h2>Booking Engine</h2>
				<domain-configs 
					class="booking_engine"
					[record]="datasetRS.record.value.domain_conf.channels && datasetRS.record.value.domain_conf.channels.booking_engine"
					[datACS]='datasetACS'
					[viewFormConfig]="'bookingEngineForm'"
					[showActions]="true"
					[elevate]='false'
					[rowDb]="datasetRS.record.value">
				</domain-configs>
                <h2>Back Office</h2>
                <domain-configs
                    class="backoffice"
                    [record]="datasetRS.record.value.domain_conf.channels && datasetRS.record.value.domain_conf.channels.backoffice"
                    [datACS]='datasetACS'
                    [viewFormConfig]="'backofficeForm'"
                    [showActions]="true"
                    [elevate]='false'
                    [rowDb]="datasetRS.record.value">
                </domain-configs>
			</mat-expansion-panel>
		</ng-template>
	</mat-tab>

	<mat-tab [label]='"Dati Aziendali" | translate'>
		<ng-template matTabContent>
			<mat-card>
				<mat-progress-bar class='mt-24 mb-24' style='min-width:300px' mode="indeterminate" *ngIf='datasetACS.loading.value'></mat-progress-bar>
				<div class='mt-24' *ngIf='datasetACS.ready.value'>
					<form-viewer
						#entityFormViewer
						[dataContainerService]='datasetACS'
						[formConfig]='infoFormConfig'
						[record]='datasetRS.record.value.entity'
					></form-viewer>
			
					<div class='mt-24 mb-24' fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
						<button color='accent' [disabled]='datasetACS.loading.value' mat-flat-button (click)='onSave()'>
							<span>{{'Salva' | translate}}</span>
						</button>
					</div>
				</div>
			</mat-card>
		</ng-template>
	</mat-tab>

	<mat-tab [label]='"Traduzioni Voucher" | translate'>
		<ng-template matTabContent>
			<mat-card>
				<common-i18n-form-step 
					[stepConfig]='stepConfig' 
					[formConfig]='voucherLangFormConfig' 
					[parentDatasetACS]='datasetACS'
					[parentDatasetRS]='datasetRS'
					[hasNext]='false'
					[hasPrevious]='false'
				></common-i18n-form-step>
			</mat-card>
		</ng-template>
	</mat-tab>
	
	<mat-tab [label]="'Export Pratiche' | translate">
		<ng-template matTabContent>
			<dataset-list
				[showTitle]='false'
				[elevate]='false'
				datasetCode='trip_package_customer_settings'
				[parentDatasetACS]='datasetACS'
				[parentDatasetRS]='datasetRS'
				[filters]='{domain_id: datasetRS.recordId }'
				[autoFetchData]='false'>
			</dataset-list>
		</ng-template>
	</mat-tab>

</mat-tab-group>
