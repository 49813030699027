import { IFieldDefinition, ValueTypes, InputTypes, ListViewItemTypes } from 'app/interfaces';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import tripPackageIdField from 'app/configs/commons/shared-field-configs/trip-package-id.field';
import { fieldsMap as ActualTripFieldsMap } from 'app/configs/datasets/actual_trips/fields';
import { convertToUTCDay } from 'app/helpers/date.helper';
import { categories } from './constants';
import { AppService } from 'app/services/app.service';
import { Validators } from '@angular/forms';

const fieldsMap = new Map<string, IFieldDefinition>();

fieldsMap.set('from_date', {
	key: 'from_date',
	name: 'Inizio periodo di confronto',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'legacy'
});

fieldsMap.set('to_date', {
	key: 'to_date',
	name: 'Fine periodo di confronto',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'legacy'
});

fieldsMap.set('from_date_comparison', {
	key: 'from_date_comparison',
	name: 'Inizio periodo di confronto',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'legacy'
});

fieldsMap.set('to_date_comparison', {
	key: 'to_date_comparison',
	name: 'Fine periodo di confronto',
	inputType: InputTypes.DATE,
	valueType: ValueTypes.DATE,
	appearance: 'legacy'
});

fieldsMap.set('comparison_type', {
	key: 'comparison_type',
	name: 'Tipolo confronto',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	appearance: 'legacy',
	options: [
		{
			label: 'Anno precedente',
			value: 'year'
		},
		{
			label: 'Periodo precedente',
			value: 'period'
		},
		{
			label: 'Periodo personalizzato',
			value: 'custom'
		},
		{
			label: 'Nessun confronto',
			value: 'none'
		}
	]
});

fieldsMap.set('filter_type', {
	key: 'filter_type',
	name: 'Tipologia filtro',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	appearance: 'legacy',
	options: [
		{
			label: 'Filtra per data di prenotazione',
			value: 'booking'
		},
		{
			label: 'Filtra per data di partenza',
			value: 'departure'
		}
	]
});

fieldsMap.set('tipo_fatturato', {
	key: 'tipo_fatturato',
	name: 'Tipo fatturato',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	appearance: 'legacy',
	options: [
		{
			label: 'Fatturato netto',
			value: 'fatturato_netto'
		},
		{
			label: 'Fatturato lordo',
			value: 'fatturato_lordo'
		}
	]
});

fieldsMap.set('sort_type', {
	key: 'sort_type',
	name: 'Ordinamento',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	appearance: 'legacy',
	options: [
		{
			label: 'Ordinamento per totale pratica',
			value: '',
			group: [
				{
					label: 'Totale pratica - Ordine decrescente',
					value: 'total_amount_desc'
				},
				{
					label: 'Totale pratica - Ordine crescente',
					value: 'total_amount_asc'
				}
			]
		},
		{
			label: 'Ordinamento per partecipanti pratica',
			value: '',
			group: [
				{
					label: 'Totale partecipanti - Ordine decrescente',
					value: 'participants_desc'
				},
				{
					label: 'Totale partecipanti - Ordine crescente',
					value: 'participants_asc'
				}
			]
		},
		{
			label: 'Ordinamento per data di prenotazione',
			value: '',
			group: [
				{
					label: 'Data prenotazione - Ordine decrescente',
					value: 'booking_date_desc'
				},
				{
					label: 'Data prenotazione - Ordine crescente',
					value: 'booking_date_asc'
				}
			]
		},
		{
			label: 'Ordinamento per data di partenza',
			value: '',
			group: [
				{
					label: 'Data partenza - Ordine decrescente',
					value: 'trip_date_desc'
				},
				{
					label: 'Data partenza - Ordine crescente',
					value: 'trip_date_asc'
				}
			]
		}
	]
});

fieldsMap.set('trip_package_id', {
	key: 'trip_package_id',
	name: 'Filtro pacchetto',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_packages',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('start_station_id', {
	key: 'start_station_id',
	name: 'Filtro nome stazione',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	customActionCode: '/dataset/trenitalia/command/stations',
	remoteOptions: {
		getLabel(option): any {
			if (!option) return '';
			return option.name;
		},
		getValue(option): any {
			if (!option) return;
			return option.service_id;
		},
	},
});

fieldsMap.set('actual_trip_id', {
	key: 'actual_trip_id',
	name: 'Filtro per partenza',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'actual_trips',
	inputConfig: {
		type: InputTypes.DATASET_SEARCH,
		clearable: true,
		viewConfig: {
			itemViewType: ListViewItemTypes.TABLE,
			defaultSortBy: 'start_day|start_time',
			defaultSortDirection: 'asc',
			showSearchBar: false,
			filterForm: {
				fields: [
					cloneFieldDefinition(ActualTripFieldsMap.get('start_day'), {
						formValidators: [],
						inputConfig: {
							type: InputTypes.COMPARE_DATE,
							clearable: true
						},
					}),
					cloneFieldDefinition(tripPackageIdField)
				]	
			},
			columns: [
				{
					title: 'Pacchetto',
					key: 'package_description',
					fieldDefinition: {
						name: 'Pacchetto',
						key: 'package_description',
						valueType: ValueTypes.STRING,
						getDisplayValue(record: any): string{
							if(!record || !record.package) return '---';
							return record.package.description;
						}
					}
				},
				{
					title: 'Tipo',
					key: 'icon_type'
				},
				{
					title: 'Nome',
					key: 'meeting_point_list_description'
				},
				{
					title: 'Data Partenza',
					key: 'start_date_time'
				},
				{
					title: 'Disponibilità',
					key: 'availability'
				},
				{
					title: 'Lingua',
					key: 'languages'
				}
			]
		}
	},
	remoteOptions: {
		limit: 50,
		getParams(dataContainerService: IDataContainerService, record: any){
			return {
				start_day: JSON.stringify(['>=', convertToUTCDay().toISOString()]),
				only_bookable: 1
			};
		},
		getLabel(option, dataContainerService: IDataContainerService): any{
			if(!option) return '';
			// fire event
			dataContainerService.appService.eventEmitter.emit({
				name: 'selected_label_actual_trip_id', 
				target: option.display_name
			});
			// finally return label
			return option.display_name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('trip_category_id', {
	key: 'trip_category_id',
	name: 'Filtro categoria',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_categories',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('trip_resource_id', {
	key: 'trip_resource_id',
	name: 'Filtro risorse',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'trip_resources',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('product_id', {
	key: 'product_id',
	name: 'Filtro prodotti',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'products',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	},
	skipIf(record, dataContainerService){
		return !dataContainerService || dataContainerService.getDomainSetting('enabled_modules.products.enabled') != true;
	}
});

fieldsMap.set('entity_customer_id', {
	key: 'entity_customer_id',
	name: 'Filtro clienti',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_customers',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('entity_id', {
	key: 'entity_id',
	name: 'Filtro intestatario',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entities',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return option.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('user_id', {
	key: 'user_id',
	name: 'Filtro utenti',
	appearance: 'legacy',
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'users',
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
		clearable: true
	},
	remoteOptions: {
		limit: 50,
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.access_id + ')' + option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		}
	}
});

fieldsMap.set('coupon_code', {
	key: 'coupon_code',
	name: 'Codice coupon',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	appearance: 'legacy'
});

fieldsMap.set('origin_channel', {
	key: 'origin_channel',
	name: 'Origine prenotazione',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	appearance: 'legacy',
	options: [
		{
			label: 'Back Office',
			value: 'backoffice'
		},
		{
			label: 'Direct Online',
			value: 'direct_online'
		},
		{
			label: 'Direct Offline',
			value: 'direct_offline'
		},
		{
			label: 'API',
			value: 'api'
		}
	]
});

fieldsMap.set('status', {
	key: 'status',
	name: 'Stato prenotazione',
	valueType: ValueTypes.ARRAY,
	inputType: InputTypes.MULTI_SELECT,
	appearance: 'legacy',
	options: [
		{
			label: 'Confermato',
			value: 'confirmed'
		},
		{
			label: 'Cancellato',
			value: 'canceled'
		},
		{
			label: 'Scaduto',
			value: 'expired'
		},
		{
			label: 'Preventivo',
			value: 'draft'
		},
		{
			label: 'Opzionale',
			value: 'optional',
		}
	]
});

fieldsMap.set('branch_code', {
	key: 'branch_code',
	name: 'Filiale',
	appearance: 'legacy',
	inputType: InputTypes.DATASET_AUTOCOMPLETE,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'branches',
	remoteOptions: {
		limit: 100,
		getLabel(option, appService: AppService): any{
			if(!option) return '';
			// fire event
			if (appService){
				appService.eventEmitter.emit({
					name: 'selected_label_branch_code', 
					target: '(' + option.code + ') ' + option.name
				});
			}
			// finally return label
			return '(' + option.code + ') ' + option.name;
		},
		getValue(option): any{
			if(!option) return;
			return option.code;
		},
	}
});

fieldsMap.set('groups', {
	key: 'groups',
	name: 'Gruppi',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.DATASET_SELECT,
	},
	datasetCode: 'select_options',
	remoteOptions: {
		supportCreate: false,
		limit: 20,
		getParams(dataContainerService: IDataContainerService, record?: any, options?): any{
			return {
				options_code: 'bi-statistics-groups'
			};	
		},
		getLabel(option): string{
			if(!option) return '';
			return option.label;
		},
		getValue(option): string{
			if(!option) return '';
			return option;
		},
		getDisplay(option): any{
			if(!option) return '';
			return option.label;
		}
	},
	appearance: 'legacy'
});

fieldsMap.set('trip_booking_package_target_milano_card', {
	key: 'trip_booking_package_target',
	name: 'Tipo Attivazione Milano Card',
	valueType: ValueTypes.STRING,
	inputType: InputTypes.SELECT,
	appearance: 'legacy',
	options: [
		{
			label: 'Online',
			value: 'milano_card'
		},
		{
			label: 'Offline',
			value: 'milano_card_offline'
		}
	],
	showIf(record, dataContainerService?) {
		const moduleTicketingEnabled = dataContainerService && dataContainerService.getValueFromKeyPath('domainModules.ticketing.enabled');
		return moduleTicketingEnabled;
	}
});

fieldsMap.set('delivery_status_milano_card', {
	key: 'delivery_status',
	name: 'Stato Consegna Milano Card',
	valueType: ValueTypes.STRING,
	inputType: InputTypes.SELECT,
	appearance: 'legacy',
	options: [
		{
			label: 'Consegnato',
			value: 'consegnato'
		},
		{
			label: 'In Consegna',
			value: 'in consegna'
		},
		{
			label: 'Standby',
			value: 'standby'
		},
		{
			label: 'Scaduto',
			value: 'scaduto'
		}
	],
	showIf(record, dataContainerService?) {
		const moduleTicketingEnabled = dataContainerService && dataContainerService.getValueFromKeyPath('domainModules.ticketing.enabled');
		return moduleTicketingEnabled;
	}
});

export default fieldsMap;
