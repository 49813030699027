import { Injectable, Inject, Optional, Injector } from '@angular/core';
import { BehaviorSubject, Subject, Observable, Observer, Subscription } from 'rxjs';
import { IDatasetActionConfig, IDatasetConfig, DatasetActionOpenType, IFormConfig, IFormViewConfig, IListViewConfig, IDetailViewConfig } from 'app/interfaces';
import {AppService} from 'app/services/app.service';
import {AuthService} from 'app/services/auth.service';
import { DatasetRecordService } from './dataset-record.service';
import {default as DatasetConfigurations} from 'app/configs/datasets';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { PageClosureContainerService } from 'app/main/pages/page-closure-container.service';
import { DatasetService } from './dataset.service';
import { DomainFilterService } from 'app/services/domain-filter.service';
import { HttpHeaders } from '@angular/common/http';
import { SelectDomainDialogComponent } from '../dialogs/select-domain-dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface IActionExecute{
	code: string;
	data?: any;
	options?: any;
}

export enum DatasetEvents{
	CREATED,
	EDITED,
	DELETED,
	ACTION_REALOAD_LIST,
	DOMAIN_SELECTED,
	LIST_FIlTER_CHANGED
}

export interface IDatasetEvent{
	eventName: DatasetEvents;
	data?: any;
}

export interface DatasetActionContainerServiceInitOptions{
	datasetCode: string;
	actionCode: string;
	actionConfig?: IDatasetActionConfig<any>;
	parentDatasetACS?: DatasetActionContainerService;
	parentDatasetRS?: DatasetRecordService;
	fetchData?: boolean;
	useBeforeCalls?: boolean;
	datasetRS?: DatasetRecordService;
}

export function getDatasetConfigAction(datasetCode: string, actionCode: 'list'): IDatasetActionConfig<IListViewConfig>|null;
export function getDatasetConfigAction(datasetCode: string, actionCode: 'edit'|'create'): IDatasetActionConfig<IFormViewConfig>|null;
export function getDatasetConfigAction(datasetCode: string, actionCode: 'list'|'edit'|'create'|'detail'): IDatasetActionConfig<IListViewConfig|IFormViewConfig|IDetailViewConfig>|null{
	const datasetConfig: IDatasetConfig = DatasetConfigurations[datasetCode];
	if(!datasetConfig){
		console.warn('dataset Config not found for', datasetCode);
		return null;
	}
	if(datasetConfig && datasetConfig.defaultActions[actionCode]){
		return datasetConfig.defaultActions[actionCode];
	}
	console.warn('dataset action Config not found for', datasetCode, actionCode);
	return null;
}

@Injectable()
export class DatasetActionContainerService implements IDataContainerService{

	public datasetCode: string;
	public actionCode: string;
	public datasetConfig: IDatasetConfig;
	public actionConfig: IDatasetActionConfig<any>;
	public datasetEvent: Subject<IDatasetEvent>;
	public parentDatasetACS: DatasetActionContainerService;
	public parentDatasetRS: DatasetRecordService;
	public datasetData: any = {};
	public loading: BehaviorSubject<boolean>;

	public datasetDataChanged: Subject<string[]>;
	public ready: BehaviorSubject<boolean>;
	public fetchParamMap: Map<string, any>;
	public sourceDataLoaded = false;
	public isNestedChild = false;
	public parentDatasetACSReadySubscription: Subscription;
	public datasetRS: DatasetRecordService;
	public viewConfig: any;
	public contextData: any = {};

	constructor(
		public appService: AppService,
		public pageClosureContainer: PageClosureContainerService,
		public authService: AuthService,
		private datasetService: DatasetService,
		public domainFilterService: DomainFilterService,
		public translationService: TranslateService,
		public injector: Injector
	){
		this.datasetEvent = new Subject();
		this.loading = new BehaviorSubject(false);
		this.ready = new BehaviorSubject(false);
		this.datasetDataChanged = new Subject();
		if(!this.domainFilterService) console.log('not domainFilterService', this);
	}

	init(options: DatasetActionContainerServiceInitOptions): BehaviorSubject<Boolean>{
		this.actionCode = options.actionCode;
		this.datasetCode = options.datasetCode;

		const datasetConfig: IDatasetConfig = DatasetConfigurations[options.datasetCode];
		if(!datasetConfig){
			console.warn('dataset Config not found for', options.datasetCode);
		}
		this.datasetConfig = datasetConfig;
		if(datasetConfig && datasetConfig.defaultActions[options.actionCode]){
			this.actionConfig = datasetConfig.defaultActions[options.actionCode];
		}
		if(options.actionConfig){
			this.actionConfig = options.actionConfig;
		}

		this.datasetRS = options.datasetRS;
		this.parentDatasetACS = options.parentDatasetACS;
		this.parentDatasetRS = options.parentDatasetRS;

		if(this.parentDatasetACS){
			this.isNestedChild = this.parentDatasetACS.datasetCode === this.datasetCode && this.parentDatasetACS.actionCode === this.actionCode;
		}
		if(!this.viewConfig && this.actionConfig) this.viewConfig = this.actionConfig.viewConfig;

		// fetch data also in nested ActionContainer
		if(this.parentDatasetACS && options.fetchData !== false){
			this.loadSourceData(options.useBeforeCalls)
			.subscribe({
				next: () => {
					this.ready.next(true);
				},
				error: () => {
					this.ready.next(true);
				}
			});
		}else if(this.parentDatasetACS){
			this.parentDatasetACSReadySubscription = this.parentDatasetACS.ready
			.subscribe(value => {
				if(value){
					this.ready.next(true);
					if(this.parentDatasetACSReadySubscription) this.parentDatasetACSReadySubscription.unsubscribe();
				}
			});
		}else if(options.fetchData === true){
			this.loadSourceData(options.useBeforeCalls)
			.subscribe({
				next: () => {
					this.ready.next(true);
				},
				error: () => {
					this.ready.next(true);
				}
			});
		}else{
			this.ready.next(true);
		}
		return this.ready;
	}

	/**
	 * Load all relative data
	 */
	loadSourceData(useBeforeCalls = true): Observable<boolean>{
		return Observable.create((observer: Observer<boolean>) => {
			this.loading.next(true);
			const hasProperties = this.hasValueInKeyPath(this.datasetConfig.name + '.' + this.datasetConfig.name + '_property_definitions');
			
			this.datasetService.fetchSourceData(this.datasetConfig, useBeforeCalls && this.actionConfig && this.actionConfig.beforeCalls, this.fetchParamMap, {
				excludeProperties: hasProperties
			})
			.subscribe({
				next: result => {
					this.putAllDatasetData(result);
					this.sourceDataLoaded = true;
					this.loading.next(false);
					observer.next(true);
				},
				error: () => {
					this.loading.next(false);
					observer.error(true);
				}
			});
		});
	}

	/**
	 * Fetch only properties definition targets
	 */
	loadPropertyDefinitions(): Observable<boolean>{
		return Observable.create((observer: Observer<boolean>) => {
			this.loading.next(true);
			this.datasetService.fetchPropertyDefinitions(this.datasetConfig)
			.subscribe({
				next: result => {
					this.putAllDatasetData(result);
					this.loading.next(false);
					observer.next(true);
				},
				error: () => {
					this.loading.next(false);
					observer.error(true);
				}
			});
		});
	}

	getContextualDomainHeaders(): HttpHeaders {
		let headers = new HttpHeaders({});
		if(this.hasValueInKeyPath('contextual_domain_id')){
			headers = headers.append('X-Domain-Id', this.getValueFromKeyPath('contextual_domain_id'));
		} else if (!this.hasValueInKeyPath('contextual_domain_id') && this.parentDatasetACS && this.parentDatasetACS.hasValueInKeyPath('contextual_domain_id')) {
			headers = headers.append('X-Domain-Id', this.parentDatasetACS.getValueFromKeyPath('contextual_domain_id'));
		}
		return headers;
	}

	getDomainSetting(path?: string, domainId?: string){
		domainId = domainId || this.getValueFromKeyPath('contextual_domain_id') || (this.parentDatasetACS && this.parentDatasetACS.getValueFromKeyPath('contextual_domain_id')) || 'current';
		return this.domainFilterService && this.domainFilterService.getDomainSetting(domainId, path);
	}

	canCreate(): boolean{
		const staticAllowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
		if(!staticAllowedActionCodes) return false;
		return staticAllowedActionCodes.includes('create');
	}

	canDetail(record?: any): boolean{
		if(!record || Boolean(record.deleted_at)) return false;
		let allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
		if(record && record.allowed_actions) allowedActionCodes = record.allowed_actions;
		if(!allowedActionCodes) return false;
		return allowedActionCodes.includes('view_detail');
	}
	canEdit(record?: any): boolean{
		if(!record || Boolean(record.deleted_at)) return false;
		let allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
		if(record && record.allowed_actions) allowedActionCodes = record.allowed_actions;
		if(!allowedActionCodes) return false;
		return allowedActionCodes.includes('edit');
	}
	
	canEditBookingDate(record?: any): boolean {
		if(!record || Boolean(record.deleted_at)) return false;
		let allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
		if(record && record.allowed_actions) allowedActionCodes = record.allowed_actions;
		if(!allowedActionCodes) return false;
		return allowedActionCodes.includes('edit_booking_date');
	}
	
	canDelete(record?: any): boolean{
		if(!record || Boolean(record.deleted_at)) return false;
		let allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
		if(record && record.allowed_actions) allowedActionCodes = record.allowed_actions;
		if(!allowedActionCodes) return false;
		return allowedActionCodes.includes('delete');
	}

	canRestore(record?: any): boolean{
		if(!record) return false;
		if(!Boolean(record.deleted_at)) return false;
		let allowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
		if(record && record.allowed_actions) allowedActionCodes = record.allowed_actions;
		if(!allowedActionCodes) return false;
		return allowedActionCodes.includes('restore');
	}

	canStaticAction(actionCode: string): boolean{
		const staticAllowedActionCodes = this.getValueFromKeyPath(this.datasetCode + '.staticAllowedActionCodes');
		if(!staticAllowedActionCodes) return false;
		return staticAllowedActionCodes.includes(actionCode);
	}

	canAction(record: any, actionCode: string): boolean{
		if(!record) return false;
		let allowedActionCodes = [];
		if(record && record.allowed_actions) allowedActionCodes = record.allowed_actions;
		return allowedActionCodes.includes(actionCode);
	}

	getViewConfig(viewConfig?: any): any{
		if(!viewConfig) viewConfig = this.viewConfig;
		if(!viewConfig && this.actionConfig) viewConfig = this.actionConfig.viewConfig;
		return viewConfig;
	}

	actionEnabled(action: string, viewConfig?: any): boolean{
		const datasetConfig = this.datasetConfig;
		if (!datasetConfig) return false;
		// use the default viewconfig
		viewConfig = this.getViewConfig(viewConfig);
		if(viewConfig){
			if(viewConfig.actions){
				if(typeof(viewConfig.actions) === 'object' && typeof(viewConfig.actions[action]) === 'boolean'){
					return viewConfig.actions[action];
				}
				if(!viewConfig.actions.includes(action)) return false;
			}
		}
		const actionEnabled = datasetConfig.enabledActions[action];
		if (typeof(actionEnabled) === 'function'){
			return actionEnabled(this.authService.userDataObserver.getValue());
		}else if (typeof(actionEnabled) === 'boolean'){
			return actionEnabled;
		}
		return true;
	}

	getDefaultOpenType(action: string): DatasetActionOpenType{
		const datasetConfig = this.datasetConfig;
		return datasetConfig.defaultActions[action].openType;
	}

	putDatasetData(key: string, value: any): void{
		this.datasetData[key] = value;
		this.datasetDataChanged.next([key]);
	}

	putAllDatasetData(data: any): void{
		const oldDatasetData = this.datasetData;
		const newDatasetData = Object.assign({}, oldDatasetData);
		const keys = [];
		for (const key in data) {
			if(!Object.prototype.hasOwnProperty.call(data, key)) continue;
			if(key === this.datasetCode && typeof(data[key]) === 'object'){
				for(const key2 in data[key]){
					if(!Object.prototype.hasOwnProperty.call(data[key], key2)) continue;
					newDatasetData[key2] = data[key][key2];
					keys.push(key2);
				}
			}else{
				newDatasetData[key] = data[key];
				keys.push(key);
			}
		}
		this.datasetData = newDatasetData;
		this.datasetDataChanged.next(keys);
	}

	putContextData(key, value): DatasetActionContainerService{
		this.contextData[key] = value;
		return this;
	}

	removeContextData(key): DatasetActionContainerService{
		delete this.contextData[key];
		return this;
	}

	getData(debug?: boolean): any{
		const data = {[this.datasetCode]: Object.assign({}, this.datasetData)};
		if(this.parentDatasetRS && (!this.datasetRS || this.parentDatasetRS.getDatasetCode() !== this.datasetCode)){
			if(!data[this.parentDatasetRS.getDatasetCode()]) data[this.parentDatasetRS.getDatasetCode()] = {};
			data[this.parentDatasetRS.getDatasetCode()].record = this.parentDatasetRS.record.value;
			data[this.parentDatasetRS.getDatasetCode()].recordId = this.parentDatasetRS.recordId;
			if(this.parentDatasetRS.record.value && this.parentDatasetRS.record.value.domain && this.parentDatasetRS.record.value.domain.id){
				data['contextual_domain_id'] = this.parentDatasetRS.record.value.domain.id;
				data['contextual_domain'] = this.parentDatasetRS.record.value.domain;
			}
		}
		
		if(this.datasetRS){
			data[this.datasetRS.getDatasetCode()].record = this.datasetRS.record.value;
			data[this.datasetRS.getDatasetCode()].recordId = this.datasetRS.recordId;

			if(this.datasetRS.record.value && this.datasetRS.record.value.domain && this.datasetRS.record.value.domain.id){
				data['contextual_domain_id'] = this.datasetRS.record.value.domain.id;
				data['contextual_domain'] = this.datasetRS.record.value.domain;
			}
		}
		if(this.datasetConfig && this.datasetConfig.nestedProperties){
			for (const code of this.datasetConfig.nestedProperties) {
				if(data[this.datasetCode][code]){
					data[code] = data[this.datasetCode][code];
				}
			}
		}
		for (const key in this.contextData) {
			if(!Object.prototype.hasOwnProperty.call(this.contextData, key)) continue;
			data[key] = this.contextData[key];
		}
		data.datasetCode = this.datasetCode;
		return data;
	}

	_getValueFromKeyPath(data: any, keys: Array<string>): boolean{
		if(data == null || data === undefined) return null;
		if(!keys || keys.length === 0) return data;
		const currentKey = keys[0];
		return this._getValueFromKeyPath(data[currentKey], keys.slice(1));
	}

	getValueFromKeyPath(keyPath: string, defaultValue?: string): any{
		const keys = keyPath.split('.');
		const data = this.getData();
		const value = this._getValueFromKeyPath(data, keys);
		const has = value !== null && value !== undefined;
		if(has) return value;
		if(this.parentDatasetACS){
			return this.parentDatasetACS.getValueFromKeyPath(keyPath, defaultValue);
		}else if(this.pageClosureContainer){
			return this._getValueFromKeyPath(this.pageClosureContainer.getSharedData(), keys);
		}
		return defaultValue;
	}
	
	_hasValueInKeyPath(data: any, keys: Array<string>): boolean{
		if(data == null || data === undefined) return false;
		if(!keys || keys.length === 0) return true;
		const currentKey = keys[0];
		return this._hasValueInKeyPath(data[currentKey], keys.slice(1));
	}

	hasValueInKeyPath(keyPath: string): boolean{
		const keys = keyPath.split('.');
		const value = this._hasValueInKeyPath(this.getData(), keys);
		const has = value === true;
		if(has) return value;
		if(this.parentDatasetACS){
			return this.parentDatasetACS.hasValueInKeyPath(keyPath);
		}else if(this.pageClosureContainer){
			return this._hasValueInKeyPath(this.pageClosureContainer.getSharedData(), keys);
		}
		return false;
	}

	getListFiltersCacheKey(prevKey?: string): string{
		let filterKey = "";
		if(!prevKey){
			filterKey = this.authService.userDataObserver.value.session_id + this.datasetCode;
			if(this.domainFilterService.filterDomainId.value) filterKey += this.domainFilterService.filterDomainId.value;
		}else filterKey += prevKey;
		// if on detail page with parents
		if(this.actionCode == 'detail' && this.datasetRS || this.actionCode == 'edit' && this.datasetRS){
			filterKey += this.datasetCode + this.datasetRS.recordId;
		}
		if(this.parentDatasetACS) return this.parentDatasetACS.getListFiltersCacheKey(filterKey);
		
		return filterKey;
	}

	isAdministrator(): boolean{
		return this.authService.isAdministrator();
	}

	getFormConfig(name: string): IFormConfig{
		if(!this.datasetConfig) return null;
		if(this.datasetConfig.formConfigsMap){
			return this.datasetConfig.formConfigsMap[name];
		}
		return null;
	}

	getCreateRoute(customActionCode?: any): string{
		let route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/create';
		if (customActionCode) {
			route = '/dataset/' + customActionCode + '/create';
		}
		if(this.datasetConfig.pivot){
			const recordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
			if(!recordId){
				console.error('no parentDatasetRS recordId defined', this);
				return route;
			}
			route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId + '/create';
		}
		return route;
	}

	getUpdateRoute(record: any, customActionCode?: any, customRecordId?: any): string{
		let recordId;
		if (record && !customRecordId) {
			recordId = record.id;
		} else {
			recordId = customRecordId;
		}

		let route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/update/' + recordId;
		if (customActionCode) {
			route = '/dataset/' + customActionCode + '/update/' + recordId;
		}
		if(this.datasetConfig.pivot){
			const parentRecordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
			if(!parentRecordId){
				console.error('no parentDatasetRS parentRecordId defined', this);
				return route;
			}
			const id = record.pivot[this.datasetConfig.pivot.foreignKey];
			if(!id){
				console.error('no foreignKey defined', record, this.datasetConfig.pivot.foreignKey);
			}
			route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + parentRecordId + '/update/' + id;
		}
		return route;
	}

	getUpdatePropertiesRoute(record: any): string{
		return '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/set/properties/' + record.id;
	}

	getListRoute(): string{
		let route = '/dataset/' + this.datasetConfig.ajaxDatasetCode;
		if(this.datasetConfig.pivot){
			let recordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
			if(!recordId) recordId = this.getValueFromKeyPath(this.datasetCode + '.filters.parentRecordId');
			if(!recordId){
				console.warn(this.datasetConfig.pivot.parentDatasetCode + '.recordId', this.getData(), this);
				return null;
			}
			route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + recordId;
		}
		return route;
	}

	getDeleteRoute(record): string{
		let route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/delete/' + record.id;
		if(this.datasetConfig.pivot){
			let parentRecordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
			if(this.datasetConfig.pivot.parentForeignKey && record.pivot[this.datasetConfig.pivot.parentForeignKey]){
				parentRecordId = record.pivot[this.datasetConfig.pivot.parentForeignKey];
			}
			if(!parentRecordId){
				console.error('no parentDatasetRS parentRecordId defined', this);
				return route;
			}
			const id = record.pivot[this.datasetConfig.pivot.foreignKey];
			if(!id){
				console.error('no foreignKey defined', record, this.datasetConfig.pivot.foreignKey);
			}
			route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + parentRecordId + '/delete/' + id;
		}
		return route;
	}

	getRestoreRoute(record): string{
		let route = '/dataset/' + this.datasetConfig.ajaxDatasetCode + '/restore/' + record.id;
		if(this.datasetConfig.pivot){
			const parentRecordId = this.getValueFromKeyPath(this.datasetConfig.pivot.parentDatasetCode + '.recordId');
			if(!parentRecordId){
				console.error('no parentDatasetRS parentRecordId defined', this);
				return route;
			}
			const id = record.pivot[this.datasetConfig.pivot.foreignKey];
			if(!id){
				console.error('no foreignKey defined', record, this.datasetConfig.pivot.foreignKey);
			}
			route = '/pivot/' + this.datasetConfig.pivot.pivotCode + '/dataset/' + this.datasetConfig.pivot.parentDatasetCode + '/' + parentRecordId + '/restore/' + id;
		}
		return route;
	}

	getConfig<T>(datasetCode, configKey, template): T{
		const defaultDatasetConfig = DatasetConfigurations[datasetCode];
		
		const DatasetTemplateExtensions = {
			'trip_packages': {
				stepViewConfig: {
					/*0: TripPackageDefaultStepViewConfig,
					1: TripPackagestepViewConfigTemplate1*/
				}
			}
		};

		const datasetTemplateExtension = DatasetTemplateExtensions[datasetCode];

		if(datasetTemplateExtension && datasetTemplateExtension[configKey] && datasetTemplateExtension[configKey][template]) return datasetTemplateExtension[configKey][template];

		return defaultDatasetConfig[configKey];
	}

	wrapInDomain(callback: (string) => void): void{
		this.domainFilterService.wrapInDomain(SelectDomainDialogComponent, callback, this);
	}
}
