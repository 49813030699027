import {InputTypes, ValueTypes, IFieldDefinition, FieldViews} from 'app/interfaces';
import { Validators } from '@angular/forms';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import entitySupplierIdField from 'app/configs/commons/shared-field-configs/entity-supplier-id.field';
import { cloneFieldDefinition } from 'app/helpers/field.helper';
import { FieldHelper } from 'app/helpers';

const fieldsMap: Map<string, IFieldDefinition> = new Map();

const resourceSubType =[
	{
		"value": "ASS",
		"label": "ASSICURAZIONE MEDICO E BAGAGLIO"
	},
	{
		"value": "ACC",
		"label": "ACCOMPAGNATORE"
	},
	{
		"value": "GUI",
		"label": "GUIDA TURISTICA"
	},
	{
		"value": "NAV",
		"label": "NAVIGAZIONE"
	},
	{
		"value": "ING",
		"label": "INGRESSI"
	},
	{
		"value": "BUS",
		"label": "BUS"
	},
	{
		"value": "BUSD",
		"label": "BUS DOPPIO"
	},
	{
		"value": "BUSEX",
		"label": "BUS ESCURSIONI"
	},
	{
		"value": "BUSPT",
		"label": "BUS PROGRAMMI TOUR OPERATOR"
	},
	{
		"value": "BUSLT",
		"label": "BUS PROGRAMMI TOUR OPERATOR"
	},
	{
		"value": "BUSGEN",
		"label": "BUS GENERICO PER LINEE"
	},
	{
		"value": "RAD",
		"label": "RADIOGUIDE MILANO"
	},
	{
		"value": "REG",
		"label": "REGALONE"
	},
	{
		"value": "TRE",
		"label": "TRENO"
	},
	{
		"value": "GAD",
		"label": "GADGETS E ABBIGLIAMENTO"
	},
	{
		"value": "LIB",
		"label": "LIBRI E GUIDE TURISTICHE"
	},
	{
		"value": "PEN",
		"label": "PENALE ANNULLAMENTO"
	},
	{
		"value": "PKG",
		"label": "PACCHETTO SERVIZI"
	},
	{
		"value": "ANN",
		"label": "ASSICURAZIONE ANNULLAMENTO"
	},
	{
		"value": "RID",
		"label": "RIDUZIONE"
	},
	{
		"value": "SPE",
		"label": "SPETTACOLI E ATTIVITA' VARIE"
	},
	{
		"value": "PICKUP",
		"label": "PICK-UP"
	},
	{
		"value": "NVT",
		"label": "NAVETTA"
	}
];

fieldsMap.set('code', {
	key: 'code',
	name: 'Codice Risorsa',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	inputHint: 'Se non specificato, ne verrà creato uno automaticamente.'
});

fieldsMap.set('description', {
	key: 'description',
	name: 'Descrizione Risorsa',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ]
});

fieldsMap.set('full_description', {
	key: 'full_description',
	name: 'Descrizione completa',
	inputType: InputTypes.TEXTAREA,
	valueType: ValueTypes.STRING,
	inputHint: 'Testo completo di descrizione.'
});

fieldsMap.set('entity_supplier_id', cloneFieldDefinition(entitySupplierIdField));


fieldsMap.set('resource_type', {
	key: 'resource_type',
	name: 'Tipo risorsa',
	inputType: InputTypes.PROPERTY,
	valueType: ValueTypes.PROPERTY
});

fieldsMap.set('allotment_share_mode', {
	key: 'allotment_share_mode',
	name: 'Modalità di condivisione',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.SELECT
	},
	options: [
		{
			label: 'Per orario',
			value: 'time'
		},
		{
			label: 'Per giorno',
			value: 'day'
		}
	],
	showIf(record){
		return ['shared_allotment', 'booking_allotment'].includes(record.allotment_type);
	}
});

fieldsMap.set('allotment_booking_mode', {
	key: 'allotment_booking_mode_input_value',
	name: 'Esclusività prenotazione',
	valueType: ValueTypes.STRING,
	inputConfig: {
		type: InputTypes.SELECT
	},
	options: [
		{
			label: 'Non esclusiva',
			value: 'null'
		},
		{
			label: 'Prenotazione esclusiva',
			value: 'exclusive'
		}
	],
	defaultValue(){
		return 'null';
	},
	showIf(record){
		return ['solo_allotment', 'shared_allotment'].includes(record.allotment_type);
	}
});

fieldsMap.set('resource_sub_types', {
	key: 'resource_sub_types',
	name: 'Tipologia',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.PROPERTY,
	datasetCode: 'trip_resource_sub_types',
	remoteOptions: {
		/*getParams(dataContainerService: IDataContainerService, record: any): any{
			const propertyDefinitions = dataContainerService.getValueFromKeyPath('trip_resources.trip_resources_property_definitions');
			const params = {
				resource_type: record.resource_type
			};

			if(propertyDefinitions && propertyDefinitions.items){
				for (const propertyDefinition of propertyDefinitions.items) {
					if(propertyDefinition.code === 'resource_type'){
						params.resource_type = record[propertyDefinition.id];
					}
				}
			}
			console.log('resource_sub_types.getParams', params, propertyDefinitions, record, dataContainerService);
			return params;
		},*/
		getValue(record: any): string{
			return record.code;
		},
		getLabel(record: any): string{
			return record.description;
		}
	},
	inputHint: 'Non obbligatoria',
});

fieldsMap.set('resource_type_sub_types_view', {
	key: 'resource_type_sub_types_view',
	name: 'Tipo / Tipologia',
	inputType: InputTypes.DATASET_SELECT,
	valueType: ValueTypes.STRING,
	datasetCode: 'trip_resource_sub_types',
	getDisplayValue(record: any): string{
		const resourceType = record.properties.resource_type.label || '---',
		resourceSubType = record.properties.resource_sub_types.value || '---';
		return resourceType + " / " + resourceSubType;
	}
});


fieldsMap.set('allotment_type', {
	key: 'allotment_type',
	name: 'Tipo Allotment',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	defaultValue(): string {
		return 'free';
	},
	options: [
		{
			label: 'Libero',
			value: 'free'
		},
		{
			label: 'Solo Allotment',
			value: 'solo_allotment'
		},
		{
			label: 'Allotment Condiviso',
			value: 'shared_allotment'
		},
		{
			label: 'Booking Allotment',
			value: 'booking_allotment'
		}
	]
});

fieldsMap.set('child_from', {
	key: 'child_from',
	name: 'Bambini da',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): number{ return 4; }
});

fieldsMap.set('child_to', {
	key: 'child_to',
	name: 'Bambini da',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): number{ return 11; }
});

fieldsMap.set('adult_to', {
	key: 'adult_to',
	name: 'Adulti fino a',
	inputType: InputTypes.NUMBER,
	valueType: ValueTypes.PROPERTY,
	defaultValue(): number{ return 64; }
});

fieldsMap.set('ticket_text', {
	key: 'ticket_text',
	name: 'Testo da allegare al biglietto',
	inputType: InputTypes.TEXT,
	valueType: ValueTypes.STRING,
	formValidators: [ Validators.required ],
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const propertyDefinition = FieldHelper.getPropertyDefinition(
			dataContainerService, 'resource_sub_types',
			'trip_resources'
		);
		if(record[propertyDefinition.id] === 'TICKETTXT'){
			return true;
		}
		return false;
	}
});

fieldsMap.set('files_download_overview', {
	key: 'files_download_overview',
	name: 'Biglietti scaricati/totali',
	inputConfig: {
		type: InputTypes.READONLY,
		readOnly: true
	},
	valueType: ValueTypes.JSON,
	getDisplayValue(record, refRecord){
		if(!record.files_download_overview) return '';
		return [record.files_download_overview.downloaded, record.files_download_overview.total].join('/');
	},
	showIf(record: any, dataContainerService: IDataContainerService): boolean{
		const value = dataContainerService.getValueFromKeyPath('record.files_download_overview');
		return value && typeof(value.total) == 'number';
	}
});

/**	fieldsMap filter  */

fieldsMap.set('resource_type_filter', {
	key: 'resource_type',
	name: 'Tipo risorsa',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: [
		{
			"label": "Hotel",
			"value": "hotel"
		},
		{
			"label": "Volo/Biglietteria",
			"value": "volo/biglietteria"
		},
		{
			"label": "Transfer",
			"value": "transfer"
		},
		{
			"label": "Attività",
			"value": "activity"
		},
		{
			"label": "Crociera",
			"value": "crociera"
		},
		{
			"label": "Traghetto",
			"value": "traghetto"
		},
		{
			"label": "Pullman",
			"value": "pullman"
		},
		{
			"label": "Autonoleggio",
			"value": "autonoleggio"
		},
		{
			"label": "Noleggio",
			"value": "noleggio"
		},
		{
			"label": "Accompagnatore/Guida",
			"value": "accompagnatore/guida"
		},
		{
			"label": "Altro",
			"value": "altro"
		},
		{
			"label": "Routing",
			"value": "routing"
		}
	]
});

fieldsMap.set('resource_sub_types_filter', {
	key: 'resource_sub_types',
	name: 'Tipologia',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: resourceSubType
});

fieldsMap.set('allotment_type_filter', {
	key: 'allotment_type',
	name: 'Tipo Allotment',
	inputType: InputTypes.SELECT,
	valueType: ValueTypes.STRING,
	options: [
		{
			label: 'Libero',
			value: 'free'
		},
		{
			label: 'Solo Allotment',
			value: 'solo_allotment'
		},
		{
			label: 'Allotment Condiviso',
			value: 'shared_allotment'
		},
		{
			label: 'Booking Allotment',
			value: 'booking_allotment'
		}
	]
});


fieldsMap.set('staff_description', {
	key: 'staff_description',
	name: 'Descrizione',
	inputType: InputTypes.PROPERTY,
	valueType: ValueTypes.STRING,
	getDisplayValue(record: any): string{
		let staff_text ='';
		let list_staff_elements_description = [];
		if(Array.isArray(record.staff_elements))
			record.staff_elements.forEach(element => {
				list_staff_elements_description.push(element.entity_staff_element.description);
			});

		if(list_staff_elements_description.length > 0)
			staff_text = (list_staff_elements_description.length != 1 ? staff_text = list_staff_elements_description.join(' , ') : list_staff_elements_description[0]);
		
		return staff_text;
	}
});

fieldsMap.set('staff_filter', {
	key: 'entity_staff_element_id',
	name: 'Staff',
	inputType: InputTypes.DATASET_SEARCH,
	valueType: ValueTypes.REFERENCE,
	datasetCode: 'entity_staff_elements',
	remoteOptions: {
		limit: 100,
		icon: 'person',
		getLabel(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.entity.description;
		},
		getValue(option): any{
			if(!option) return;
			return option.id;
		},
		getDisplay(option): any{
			if(!option) return '';
			return '(' + option.code + ')' + option.entity.description;
		}
	}
});

export {fieldsMap};
