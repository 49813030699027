import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {FormDialogComponent, IFormDialogData} from "../../../../../../dialogs/form-dialog/dialog.component";
import {DatasetActionContainerService} from "../../../../services/dataset-action-container.service";
import {DatasetNavigatorService} from "../../../../services/dataset-navigator.service";
import {InputTypes, ValueTypes} from "../../../../../../../interfaces";
import {Validators} from "@angular/forms";
import * as _ from 'lodash';
import {DatasetRecordService} from "../../../../services/dataset-record.service";
import {DatasetService} from "../../../../services/dataset.service";
import {ConfirmDialogComponent} from "../../../../../../dialogs/confirm-dialog/dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AppService} from "../../../../../../../services/app.service";
import {IDataContainerService} from "../../../../../../../interfaces/data-container.interface";


@Component({
	selector: 'resource-inputs',
	templateUrl: './resource-inputs.component.html',
	styleUrls: ['./resource-inputs.component.scss']
})
export class ResourceInputsComponent implements OnInit {
	displayedColumns: string[] = ['type', 'labels', 'config', 'action'];
	@Input() data: any;
	@Output() public changed = new EventEmitter<any>();
	dataSource: any;
	record:any;
	loading=true
	
	constructor(protected dialog: MatDialog,
				private datasetACS: DatasetActionContainerService,
				private datasetRS: DatasetRecordService,
				private datasetService: DatasetService,
				private datasetNS: DatasetNavigatorService,
				private appService: AppService) {
	}
	
	ngOnInit() {
		this.loading = true;
		this.dataSource = this.data && this.data.custom_inputs ? this.data.custom_inputs : [];
		this.datasetRS.record.subscribe(record=> {
			this.record = record;
			this.dataSource = this.record.data && this.record.data.custom_inputs ? this.record.data.custom_inputs : []
			this.loading = false;
		});
		
	}
	onAdd(){
		const dialogData = this.getDialogData();
		this.datasetNS.openDialog<IFormDialogData>(FormDialogComponent, dialogData).afterClosed()
			.subscribe(res =>{
				if(res) {
						this.loading = true;
						if(!this.record.data){this.record.data = {custom_inputs:[]}}
						this.record.data['custom_inputs'].push(res);
						this.datasetService.post('/dataset/trip_resources/update/' + this.record.id, this.record).subscribe(
							res => {
								/*this.datasetRS.reload();*/
								this.changed.emit();
								this.loading = false;
								this.appService.showSuccessMessage('Input inserito')
							}
						)
				}
			});
	}
	
	onEdit(data: any,i:number) {
		data['label_it'] = data['label']['it'];
		data['label_en'] = data['label']['en'];
		const dialogData = this.getDialogData(data);
		this.datasetNS.openDialog<IFormDialogData>(FormDialogComponent, dialogData).afterClosed()
			.subscribe(res =>{
				if(res) {
						this.loading = true;
						this.record.data['custom_inputs'][i] = res;
						// @ts-ignore
						this.datasetService.post('/dataset/trip_resources/update/' + this.record.id, this.record).subscribe(
							res => {
								/*this.datasetRS.reload();*/
								this.changed.emit();
								this.loading = false;
								this.appService.showSuccessMessage('Input modificato')
							}
						)
				}
			})
	}
	
	onDelete(data) {
		this.datasetNS.openDialog(ConfirmDialogComponent,{
			title: 'Elimina campo aggiuntivo '+ data.label,
			positiveText: 'Conferma',
			negativeText: 'Annulla'
		}).afterClosed()
			.subscribe(
				res=>{
					if(res){
						this.loading = true;
						this.record.data['custom_inputs']= this.record.data['custom_inputs'].filter((input: { name: any; }) => input.name!==data.name);
						this.datasetService.post('/dataset/trip_resources/update/' + this.record.id, this.record).subscribe(
							res => {
								this.changed.emit();
								this.loading = false;
								this.appService.showSuccessMessage('Input eliminato')
							}
						)
					}
				}
			)
		
	}
	
	getDialogData(data?){
		return {
			title: 'Campi aggiuntivi',
			formData: data,
			dataContainerService: this.datasetACS,
			formConfig: {
				fields: [
					{
						key: 'type',
						name: 'Tipo',
						valueType: ValueTypes.STRING,
						inputConfig: {
							type: InputTypes.SELECT,
							hint: 'Tipologia del campo',
						},
						defaultValue: () => {
							return 'select'
						},
						options: [
							{
								label: 'Selezione numerica',
								value: 'select'
							},
							{
								label: 'Campo testo',
								value: 'input'
							},
						],
						readonlyIf(record){
							if(!record) return false;
							return record.name == 'participants'
						},
						onValueChanged(form){
							/*if(form && form.get('type') && form.get('type').value=='input'){
								form.get('min').setValidators([])
								form.get('max').setValidators([])
								form.get('value').setValidators([])
							}*/
						},
						
					},
					/*{
						key: 'name',
						name: 'Nome',
						valueType: ValueTypes.STRING,
						inputConfig: {
							type: InputTypes.TEXT,
							hint: 'nome dell \' input',
							clearable: true
						},
						readonlyIf(record){
							if(!record) return false;
							return record.name == 'participants'
						},
						formValidators: [Validators.required]
					},*/
					{
						key: 'label_it',
						name: 'Etichetta IT',
						valueType: ValueTypes.STRING,
						inputConfig: {
							type: InputTypes.TEXT,
							hint: 'Etichetta dell\'input',
							clearable: true
						},
						formValidators: [Validators.required]
					},
					{
						key: 'label_en',
						name: 'Etichetta EN',
						valueType: ValueTypes.STRING,
						inputConfig: {
							type: InputTypes.TEXT,
							hint: 'Etichetta dell\'input',
							clearable: true
						},
						formValidators: [Validators.required]
					},
					{
						key: 'min',
						name: 'Minimo',
						valueType: ValueTypes.NUMBER,
						inputConfig: {
							type: InputTypes.NUMBER,
							hint: 'Valore minimo',
							clearable: false
						},
						
						showIf(formValue: any, dataContainerService) {
							return formValue.type === 'select'
						},
						defaultValue(dataContainerService){
							return data && data.name=='participants'?1:0;
						},
						onValueChanged(form){
							if(form && form.get('min') && form.get('min').value){
								form.get('max').setValue(form.get('min').value);
								form.get('max').setValidators([ Validators.min(form.get('min').value)])
							}
						},
						formValidators: this.getMinValidator(data)
					},
					{
						key: 'max',
						name: 'Massimo',
						valueType: ValueTypes.NUMBER,
						inputConfig: {
							type: InputTypes.NUMBER,
							hint: 'Valore massimo',
							clearable: false
						},
						showIf(formValue: any, dataContainerService) {
							return formValue.type === 'select'
						},
						defaultValue(dataContainerService){
							return data && data.name=='participants'?1:0;
						},
						/*formValidators: this.getMinValidator(data)*/
					},
					{
						key: 'value',
						name: 'Valore',
						valueType: ValueTypes.STRING,
						inputConfig: {
							type: InputTypes.NUMBER,
							hint: 'Valore predefinito',
							clearable: false
						},
						defaultValue(dataContainerService: IDataContainerService, options?: any[], record?: any){
							return '';
						},
						showIf(formValue: any, dataContainerService) {
							return formValue.type === 'select'
						}
					},
					
				]
			},
			positiveText: 'Salva',
		}
		
	}
	
	
	
	getMinValidator(data?: { name: string; }){
		if(data && data.name=='participants') return [Validators.required,Validators.min(1)];
		return [Validators.min(0)]
	}
}
