import { IFieldContainer, IFieldDefinition, IFormConfig } from 'app/interfaces';
import { fieldsMap } from '../fields';

const formFields: (IFieldContainer|IFieldDefinition)[] = [
	fieldsMap.get('trip_resource_id'),
	fieldsMap.get('package_resource_type'),
	fieldsMap.get('meeting_point_unified_list_id'),
	fieldsMap.get('sort_index')
];

const config: IFormConfig = {
	title: 'Aggiungi Risorsa',
	fields: formFields 
};

export default config;
