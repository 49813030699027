import {IFieldContainer, IFieldDefinition, IFormConfig, InputTypes, ValueTypes} from "../../../../../../interfaces";
import {
	channelDefaultCustomerFields
} from "../../../../../../configs/datasets/domain_configs/fields/channels/default-customer.fields";
import {fieldsMap} from "../../../../../../configs/datasets/trip_package_language_contents/fields";
import {IDataContainerService} from "../../../../../../interfaces/data-container.interface";

const  widgetFormFields: (IFieldContainer|IFieldDefinition)[] = [
	{
		config: {
			flexLayout: 'row'
		},
		fields: [
			channelDefaultCustomerFields.get('entity_customer.entity_customer_id'),
			{
				
				key: 'widget_color',
				name: 'Colore Principale Widget',
				inputType: InputTypes.COLOR,
				valueType: ValueTypes.STRING,
				defaultValue(dataContainerService: IDataContainerService) {
					return '#F39200';
				}
			},
			{
				
				key: 'widget_type',
				name: 'Tipo',
				inputType: InputTypes.SELECT,
				valueType: ValueTypes.NUMBER,
				defaultValue(): any{ return 1; },
				options: [
					{
						value:  1,
						label: 'Pulsante'
					},
					{
						value:  2,
						label: 'Calendario'
					}
				]
			}
		]
	},
	
	
];
const widgetForm: IFormConfig = {
	fields: widgetFormFields
};

export default widgetForm
