<ng-container *ngIf='!datasetACS.loading.value'>
	<div class="resource-row" [ngClass]="is_pickup && activated ? 'pickup' : ''" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px" *ngIf="!custom_input">
		<div (click)='changeStatus()' [matTooltip]='not_activable_reason' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
			<button class="checkbox" [disabled]='not_activable_reason || is_primary || parent_resource' mat-icon-button *ngIf='!activated' [matTooltip]='is_primary?"Risorsa primaria NON attiva":"Risorsa opzionale NON attivata" | translate'>
				<mat-icon>check_box_outline_blank</mat-icon>
			</button>
			<button class="checkbox" [disabled]='not_activable_reason || is_primary || parent_resource' mat-icon-button *ngIf='activated' [matTooltip]='is_primary?"Risorsa primaria attiva":"Risorsa opzionale attivata" | translate'>
				<mat-icon>check_box</mat-icon>
			</button>
			<div fxLayout="row" fxLayoutAlign="space-between center" style="cursor: pointer;">
				<span class='ml-4 info_resource' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

					<span class="resource_description"><span *ngIf="parent_resource">{{parent_resource.description}}: </span> {{trip_resource.description}}</span>
					<span class="text-color-black font-size-14">{{datasetRS.record.value.total_amount | currency:'EUR' }}</span>
					<ng-container *ngIf="is_pickup">
						<span class="pickup">PICK-UP</span>
					</ng-container>
					<span class='secondary-text mat-caption ml-4' *ngIf="!is_primary">({{ 'Opzionale' | translate}})</span>

				</span>
			</div>


		</div>
		<ng-container *ngIf='is_pickup && activated'>
			<div class='resource_meeting_point' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
				<mat-icon>place</mat-icon>
				<span class='ml-4 mr-4'>{{'Punto di ritrovo' | translate}}: <span class='meeting_point_description' [matTooltip]='tripBookingResourceService.meetingPointPickupResource.description' *ngIf='tripBookingResourceService.meetingPointPickupResource'>{{tripBookingResourceService.meetingPointPickupResource.description}}</span></span>
				<span><mat-spinner *ngIf='tripBookingResourceService.loadPickupMeetingPoint' diameter='20'></mat-spinner></span>
				<!--<button *ngIf='!tripBookingResourceService.loadPickupMeetingPoint' mat-icon-button color='accent' [matTooltip]='"Modifica punto di ritrovo" | translate' (click)='tripBookingResourceService.editMeetingPoint(datasetRS.record.value)'>
					<mat-icon>edit</mat-icon>
				</button>-->
			</div>
		</ng-container>


	</div>

    <div class="resource-row" *ngIf="custom_input && datasetRS.record.value.data">
        <span class='ml-4 info_resource' fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <span class="resource_description">{{trip_resource.description}}</span>
        </span>
    </div>

    <ng-container *ngIf="(is_primary && activated) || custom_input && activated" >
        <div *ngIf="datasetRS.record.value.data && datasetRS.record.value.data.data.custom_inputs" class="primary-input">
            <div *ngFor="let input of datasetRS.record.value.data.data.custom_inputs" fxLayout="row" fxLayoutGap="10px">
                {{input.label}} {{datasetRS.record.value.data.details[input.name]}}
            </div>
        </div>
    </ng-container>
</ng-container>
