import {IDatasetConfig, DatasetActionOpenType, ListViewItemTypes, ValueTypes, FieldViews } from 'app/interfaces';
import {fieldsMap} from './fields';
import createForm from './forms/general.form';
import detailForm from './forms/detail.form';
import editForm from './forms/edit.form';
import { IDataContainerService } from 'app/interfaces/data-container.interface';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import * as _ from 'lodash';

const beforeCallDetail = {
	name: 'record',
	path: '/dataset/trip_package_resources/detail/:recordId',
};

function getCallParams(dataContainerService: IDataContainerService, parentData: any, params: any): any{
	const tripPackageId = dataContainerService.getValueFromKeyPath('trip_packages.recordId');
	if(tripPackageId){
		params['trip_package_id'] = tripPackageId;
	}
	return params;
}

export const TripPackageResourcesConfig: IDatasetConfig = {
	name: 'trip_package_resources',
	title: 'Risorse',
	singleTitle: 'Risorsa',
	ajaxDatasetCode: 'trip_package_resources',
	fieldsMap: fieldsMap,
	supportProperties: false,
	enabledActions: {
		detail: true,
		create: true,
		edit: true,
		list: true
	},
	formConfigsMap: {
		createForm,
		detailForm,
		editForm
	},
	defaultActions: {
		detail: {
			openType: DatasetActionOpenType.DIALOG,
			beforeCalls: [ beforeCallDetail ],
			viewConfig: {
				formConfig: detailForm,
				actions: {
					edit: false,
					delete: false
				}
			}
		},
		create: {
			openType: DatasetActionOpenType.DIALOG,
			getCallParams,
			viewConfig: {
				formConfig: createForm
			}
		},
		edit: {
			openType: DatasetActionOpenType.DIALOG,
			viewConfig: {
				formConfig: editForm
			},
		},
		list: {
			openType: DatasetActionOpenType.PAGE,
			getCallParams,
			viewConfig: {
				itemViewType: ListViewItemTypes.TABLE,
				showSearchBar: true,
				defaultSortBy: 'sort_index|created_at',
				defaultSortDirection: 'asc',
				columns: [
					/*{
						title: 'Codice',
						key: 'resource_code'
					},*/
					{
						title: 'Descrizione',
						key: 'resource_description',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record, value){
							if(!value) return '';
							const codeString = '<br><span style="color: #888;font-size:12px ">Codice: '+_.get(record, 'trip_resource.code')+'</span>';
							
							return value+codeString;
						}
					},
					{
						title: 'Tipologia',
						key: 'package_resource_type',
						fieldView: FieldViews.HTML,
						getDisplayHTML(record){
							const sortString = '<br><span style="color: #888;font-size:12px ">Priorità: '+record.sort_index+'</span>';
							if(!record) return 'Inclusa'+sortString;
							if(Number(record.package_resource_type) === 1) return 'Opzionale'+sortString;
							if(Number(record.package_resource_type) === 3) return 'Primaria'+sortString;
							if(Number(record.package_resource_type) === 2) return 'Attiva'+sortString;
							return 'Inclusa'+sortString;
						}
					},
					{
						title: 'Punti di ritrovo',
						key: 'meeting_point_unified_list_description'
					}
				],
				actions: ['create', 'detail', 'edit', 'delete'],
				itemActionsOrder: ['custom_0', 'edit', 'delete'],
				customActions: [
					{
						buttonColor: 'accent',
						buttonIcon: 'pageview',
						tooltip: 'Dettaglio risorsa',
						showIf(): boolean{ return true; },
						makeRouteLink(record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): string{
							return datasetNavigatorService.getDetailRoute('trip_resources', record.trip_resource_id);
						},
						makeQueryParams(record: any, dataContainerService: IDataContainerService, datasetNavigatorService: DatasetNavigatorService): any{
							let title = 'Torna al pacchetto';
							if(dataContainerService.hasValueInKeyPath('trip_packages.record.description')){
								title = 'Torna a ' + dataContainerService.getValueFromKeyPath('trip_packages.record.description');
							}
							return {
								step: 1,
								back_to_dataset: 'trip_packages',
								back_to_record_id: record.trip_package_id,
								back_title: title,
								back_query_params: JSON.stringify({
									step: 1,
									step_tab: 2
								})
							};
						}
					}
				]
			}
		}
	}
};
