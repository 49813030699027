import {AfterViewInit, Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import { BaseStepViewComponent } from '../base-step-view.class';
import { DatasetRecordService } from 'app/main/components/dataset/services/dataset-record.service';
import { DatasetActionContainerService } from 'app/main/components/dataset/services/dataset-action-container.service';
import { DatasetNavigatorService } from 'app/main/components/dataset/services/dataset-navigator.service';
import { DatasetService } from 'app/main/components/dataset/services/dataset.service';
import { AppService } from 'app/services/app.service';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ListForIntegrationsViewConfig from 'app/configs/datasets/domain_packages/list_for_integrations.view_config';
import { StepService } from 'app/main/components/dataset/services/step-state.service';
import widgetFormField from "./widgetFormField";
import widgetForm from "./widgetFormField";
import {FormViewerComponent} from "../../../../form-viewer/form-viewer.component";
import {FormViewerService} from "../../../../../../services/form-viewer.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector   : 'integrations-step',
	templateUrl: './step.component.html',
	styleUrls  : ['./step.component.scss'],
	providers: [DatasetActionContainerService]
})
export class IntegrationsStepComponent extends BaseStepViewComponent implements OnInit{
	
	@Input() parentDatasetACS: DatasetActionContainerService;
	@Input() hasStepState = false;
	@Input() datasetCode: string;
	@Input() integrationsDatasetCode: string;
	@ViewChild('formViewer', { static: false }) formViewer: FormViewerComponent;
	widgetForm = widgetFormField;

	loading = false;
	fetchTourOperatorSubcription: Subscription;
	tourOperators: any[];
	public domainPackageViewConfig = ListForIntegrationsViewConfig;
	private widget_entity_customer_id= ''
	public selectedWidgetColor: '#F39200';
	public widgetType = 1;
	public snippet: SafeHtml;
	constructor(
		public appService: AppService,
		public datasetRS: DatasetRecordService,
		public datasetACS: DatasetActionContainerService,
		protected datasetNavigatorService: DatasetNavigatorService,
		public datasetService: DatasetService,
		public stepService: StepService,
		private dialog: MatDialog,
		private sanitizer: DomSanitizer){
		// parent constructor
		super(datasetACS, datasetNavigatorService);
	}

	ngOnInit(): void{
		super.ngOnInit();
		this.loadTourOperators();
		// init datasetACS
		this.datasetACS.init({
			actionCode: 'list',
			datasetCode: this.integrationsDatasetCode,
			parentDatasetRS: this.datasetRS,
			parentDatasetACS: this.parentDatasetACS,
			fetchData: false,
			useBeforeCalls: false,
		}).subscribe(ready => {
			if (!ready) return;
			this.datasetACS.putContextData('contextual_domain_id', this.datasetACS.domainFilterService.filterDomainId.value);
		});
		// setta step come completato
		if (this.hasStepState){
			this.stepService.setStepState({
				dataset_code: this.datasetCode,
				form_code: 'integrations',
				record_id: this.datasetRS.recordId,
				status: 1
			});
		}
	}

	public loadTourOperators(): void{
		if(this.fetchTourOperatorSubcription){
			this.fetchTourOperatorSubcription.unsubscribe();
		}
		const params = {};
		if (this.datasetCode == 'trip_packages'){
			params['trip_package_id'] = this.datasetRS.recordId;
		}else{
			params['trip_category_id'] = this.datasetRS.recordId;
		}
		if(this.widget_entity_customer_id){
			params['widget_entity_customer_id'] = this.widget_entity_customer_id;
		}
		if(this.selectedWidgetColor){
			params['widget_color'] = this.selectedWidgetColor;
		}
		if(this.widgetType){
			params['widget_type'] = this.widgetType;
		}
		this.loading = true;
		this.fetchTourOperatorSubcription = this.datasetService.post<any[]>('/dataset/' + this.integrationsDatasetCode + '/command/get_for_tour_operators', params)
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe({
			next: response => {
				this.tourOperators = response;
				const widget = this.tourOperators.find(op => op.code==='booking_widget');
				if(widget) this.snippet = widget.snippet;
				this.loading = false;
			},
			error: response => {
				this.loading = false;
			}
		});
	}

	createTripPackageIntegration(tourOperator): void{
		tourOperator.activating = true;

		// set request body
		const data = {
			tour_operator_id: tourOperator.id,
			tour_operator_code: tourOperator.code, // used when id not exist
			enabled: 1
		};
		if (this.datasetCode == 'trip_packages'){
			data['trip_package_id'] = this.datasetRS.recordId;
		}else{
			data['trip_category_id'] = this.datasetRS.recordId;
		}

		this.datasetService.post('/dataset/' + this.integrationsDatasetCode + '/create', data)
		.subscribe({
			next: response => {
				this.loadTourOperators();
			},
			error: response => {
				tourOperator.activating = false;
			}
		});
	}

	trackByIndex(index, item): any{
		return indexedDB;
	}

	integrationUpdated(integration: any, tourOperator: any): void{
		const newTOs = [];
		for (const t of this.tourOperators) {
			if(t.id === tourOperator.id){
				t.integration = integration;
			}
			newTOs.push(t);
		}
		this.tourOperators = newTOs;
	}
	
	widgetOptions(formViewerService: FormViewerService) {
		const formValues = formViewerService.getFormData();
		this.widget_entity_customer_id = formValues['entity_customer.entity_customer_id'];
		this.selectedWidgetColor = formValues['widget_color'];
		this.widgetType = formValues['widget_type'];
		this.loadTourOperators();
	}
	
	openWidgetDialog(): void {
		this.dialog.open(WidgetDialogComponent, {
			width: '80%',
			height: '80%',
			data: { snippet: this.snippet }
		});
	}
}

@Component({
	selector: 'widget-dialog',
	template: `
        <h2 mat-dialog-title>
            Booking Widget
            <button mat-icon-button (click)="closeDialog()" style="float:right">
                <mat-icon>close</mat-icon>
            </button>
        </h2>
        <mat-dialog-content class="dialog-content">
            <iframe #iframe style="width:100%; height:100%; border:none;"></iframe>
        </mat-dialog-content>
	`,
	styles: [`
    .dialog-content {
      padding: 0;
      height: 100%;
      overflow: hidden;
    }
  `]
})
export class WidgetDialogComponent implements AfterViewInit {
	@ViewChild('iframe',{ static: false }) iframe: ElementRef<HTMLIFrameElement>;
	
	constructor(public dialogRef: MatDialogRef<WidgetDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: { snippet: string },
				private renderer: Renderer2) { }
	
	ngAfterViewInit(): void {
		const iframeEl = this.iframe.nativeElement;
		const iframeDoc = iframeEl.contentDocument || iframeEl.contentWindow.document;
		iframeDoc.open();
		iframeDoc.write(this.data.snippet);
		iframeDoc.close();
	}
	closeDialog(): void {
		this.dialogRef.close();
	}
}
